var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("flat-pickr", {
            attrs: { config: _vm.config, placeholder: "Select a date" },
            on: { "on-change": _vm.setRange },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "account-first-name" } }, [
            _vm._v("Notes"),
          ]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.new_absence.notes,
                expression: "new_absence.notes",
              },
            ],
            staticClass: "form-control",
            attrs: {
              rows: "10",
              type: "text",
              id: "account-first-name",
              placeholder: "Notes",
            },
            domProps: { value: _vm.new_absence.notes },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.new_absence, "notes", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "invalid-feedback" }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit" },
              on: { click: _vm.submitForm },
            },
            [_vm._v(" Add maintenance ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }