var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("toggle-modal")
        },
      },
    },
    [_vm._v("GO AHEAD. TELL ALL.")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }