var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkout-container", attrs: { id: _vm.planId } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-box" },
        [
          _c("div", { staticClass: "checkout-form" }, [
            _c("h1", { staticClass: "checkout-title" }, [
              _vm._v("SECURE CHECKOUT"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "accordions" }, [
              _c(
                "div",
                { staticClass: "accordion" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("shipping", {
                    attrs: {
                      states: _vm.usaStates,
                      addresses: _vm.shipping_addresses,
                      user: _vm.user,
                      rent_at: _vm.rent_at,
                      return_at: _vm.return_at,
                      securityCheck: _vm.securityCheck,
                      update_recipient_path: _vm.payment.path,
                      order: _vm.order,
                    },
                    on: {
                      openVerification: function ($event) {
                        _vm.verificationOpened = true
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "spacer" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "accordion" },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("verification", {
                    attrs: {
                      user: _vm.user,
                      addresses: _vm.shipping_addresses,
                      securityCheck: _vm.securityCheck,
                      personaEnvironment: _vm.persona_environment,
                      personaTemplateId: _vm.persona_template_id,
                      personaEnvironmentId: _vm.persona_environment_id,
                      verificationOpened: _vm.verificationOpened,
                      returningCustomer: _vm.returning_customer,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "spacer" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "accordion" },
                [
                  _vm._m(3),
                  _vm._v(" "),
                  _c("billing", {
                    attrs: {
                      states: _vm.usaStates,
                      addresses: _vm.billing_addresses,
                      shippingAddress: _vm.shipping_addresses[0],
                      payment: _vm.payment,
                      securityCheck: _vm.securityCheck,
                      order: _vm.order,
                      update_recipient_path: _vm.payment.path,
                      user: _vm.user,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "spacer" }),
              _vm._v(" "),
              _c("div", { staticClass: "accordion" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapsed",
                    attrs: { id: "checkout-review" },
                  },
                  [
                    _c("p", [
                      _vm._v(
                        "\n              Please review your order on the right and hit PLACE ORDER when\n              ready. Your credit card will be charged when your order is\n              shipped.\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkboxes" }, [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.securityCheck.terms,
                              expression: "securityCheck.terms",
                            },
                          ],
                          attrs: { name: "agreement", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.securityCheck.terms)
                              ? _vm._i(_vm.securityCheck.terms, null) > -1
                              : _vm.securityCheck.terms,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.securityCheck.terms,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.securityCheck,
                                      "terms",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.securityCheck,
                                      "terms",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.securityCheck, "terms", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(5),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.securityCheck.disclosure,
                              expression: "securityCheck.disclosure",
                            },
                          ],
                          attrs: { name: "disclosure", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.securityCheck.disclosure)
                              ? _vm._i(_vm.securityCheck.disclosure, null) > -1
                              : _vm.securityCheck.disclosure,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.securityCheck.disclosure,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.securityCheck,
                                      "disclosure",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.securityCheck,
                                      "disclosure",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.securityCheck, "disclosure", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "disclosure" } }, [
                          _vm._v(
                            "\n                  Due to the nature of the services that we provide we reserve\n                  the right to reject jewelry rental services to individuals,\n                  who do not meet the minimum merit of good credit qualifications\n                  and/or do not pass background and identity verification. In \n                  the event that a user is not approved for services, an order\n                  cancellation fee may apply.\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._m(6),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("order-details", {
            attrs: {
              products: _vm.items,
              order: _vm.order,
              rent_at: _vm.rent_at,
              return_at: _vm.return_at,
              isSubscription: _vm.isSubscription,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav", attrs: { id: "nav-desktop" } }, [
      _c("div", { staticClass: "before-chevron" }),
      _vm._v(" "),
      _c("a", { staticClass: "back-to-cart", attrs: { href: "/" } }, [
        _vm._v("Back to Shopping Cart"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ac-title" }, [
      _c("p", { staticClass: "checkout-number" }, [_vm._v("1")]),
      _vm._v(" "),
      _c("span", [_vm._v("Shipping Information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ac-title" }, [
      _c("p", { staticClass: "checkout-number" }, [_vm._v("2")]),
      _vm._v(" "),
      _c("span", [_vm._v("Identity Verification")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ac-title" }, [
      _c("p", { staticClass: "checkout-number" }, [_vm._v("3")]),
      _vm._v(" "),
      _c("span", [_vm._v("Billing Information")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ac-title" }, [
      _c("p", { staticClass: "checkout-number" }, [_vm._v("4")]),
      _vm._v(" "),
      _c("span", [_vm._v("Review Order")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "agreement" } }, [
      _vm._v(
        "\n                  I have read and accepted the\n                  "
      ),
      _c(
        "a",
        { staticClass: "tos-link", attrs: { href: "/terms-and-conditions" } },
        [
          _vm._v(
            "\n                    Terms and Conditions\n                  "
          ),
        ]
      ),
      _vm._v("\n                  .\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "save-container" }, [
      _c("button", { attrs: { id: "submit-order" } }, [_vm._v("PLACE ORDER")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }