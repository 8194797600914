var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "company-name" } }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.company.name,
                expression: "company.name",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.company.name },
            attrs: {
              type: "text",
              id: "company-name",
              placeholder: "company name",
            },
            domProps: { value: _vm.company.name },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.company, "name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.company.name
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.company.name.join(", "))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "company-name" } }, [
            _vm._v("Federal Tax ID Number"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.company.tax_id,
                expression: "company.tax_id",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.company.tax_id },
            attrs: {
              type: "text",
              id: "company-tax_id",
              placeholder: "company tax_id",
            },
            domProps: { value: _vm.company.tax_id },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.company, "tax_id", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.company.tax_id
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.company.tax_id.join(", "))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "company-name" } }, [
            _vm._v("JBT Number"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.company.jbt_number,
                expression: "company.jbt_number",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.company.jbt_number },
            attrs: {
              type: "text",
              id: "company-jbt_number",
              placeholder: "company jbt_number",
            },
            domProps: { value: _vm.company.jbt_number },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.company, "jbt_number", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.company.jbt_number
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.company.jbt_number.join(", "))),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { type: "button" },
        on: { click: _vm.update },
      },
      [_vm._v("Save changes")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }