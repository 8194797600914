<template>
  <div>
    <div class="product-page">
      <div
        class="banner"
        :id="bannerPlanId"
        v-if="collection === 'all' && type === 'all jewelry'"
      >
        <div v-if="bannerPlanId != 'sub-plan'" class="banner-details">
          <h5>RENT BY THE DAY</h5>
          <p>RENT DESIGNER JEWELRY FOR YOUR NEXT OCCASION</p>
        </div>
        <div v-if="bannerPlanId === 'sub-plan'" class="banner-details">
          <h5>SUBSCRIPTION BOX</h5>
          <p>RENT DESIGNER JEWELRY FOR ANY OCCASION</p>
        </div>
      </div>

      <div class="banner" id="collection-banner" v-if="collection != 'all'">
        <div class="banner-details">
          <h5>FEATURED COLLECTION</h5>
          <p>{{ collectionName }}</p>
        </div>
      </div>

      <div
        class="banner"
        id="necklaces-rbtd-banner"
        v-if="type === 'necklaces' && bannerPlanId != 'sub-plan'"
      >
        <div class="banner-details">
          <h5>FEATURED COLLECTION</h5>
          <p>RENT DESIGNER NECKLACES FOR YOUR NEXT OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="necklaces-subscription-banner"
        v-if="type === 'necklaces' && bannerPlanId === 'sub-plan'"
      >
        <div class="banner-details">
          <h5>SUBSCRIPTION BOX</h5>
          <p>RENT DESIGNER NECKLACES FOR ANY OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="earrings-rbtd-banner"
        v-if="type === 'earrings' && bannerPlanId != 'sub-plan'"
      >
        <div class="banner-details">
          <h5>FEATURED COLLECTION</h5>
          <p>RENT DESIGNER EARRINGS FOR YOUR NEXT OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="earrings-subscription-banner"
        v-if="type === 'earrings' && bannerPlanId === 'sub-plan'"
      >
        <div class="banner-details">
          <h5>SUBSCRIPTION BOX</h5>
          <p>RENT DESIGNER EARRINGS FOR ANY OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="bracelets-rbtd-banner"
        v-if="type === 'bracelets' && bannerPlanId != 'sub-plan'"
      >
        <div class="banner-details">
          <h5>FEATURED COLLECTION</h5>
          <p>RENT DESIGNER BRACELETS FOR YOUR NEXT OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="bracelets-subscription-banner"
        v-if="type === 'bracelets' && bannerPlanId === 'sub-plan'"
      >
        <div class="banner-details">
          <h5>SUBSCRIPTION BOX</h5>
          <p>RENT DESIGNER BRACELETS FOR ANY OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="rings-rbtd-banner"
        v-if="type === 'rings' && bannerPlanId != 'sub-plan'"
      >
        <div class="banner-details">
          <h5>FEATURED COLLECTION</h5>
          <p>RENT DESIGNER RINGS FOR YOUR NEXT OCCASION</p>
        </div>
      </div>

      <div
        class="banner"
        id="rings-subscription-banner"
        v-if="type === 'rings' && bannerPlanId === 'sub-plan'"
      >
        <div class="banner-details">
          <h5>SUBSCRIPTION BOX</h5>
          <p>RENT DESIGNER RINGS FOR ANY OCCASION</p>
        </div>
      </div>

      <div class="diamonds-collection" v-if="collection == 'diamond-jewelry-for-rent'">
        <h3>RENT DIAMOND JEWELRY FOR YOUR SPECIAL OCCASION</h3>
        <div></div>
        <p>
          Trejours offers you an affordable new to wear designer diamond jewelry to any special occasion.
          Simply choose the jewelry you like, reserve it for the date you want, and you'll get it shipped right to your doorstep.
          Experience luxury like never before - without commitment.
        </p>
      </div>

      <div class="products-wrapper">
        <div class="navigation">
          <div class="nav-box" v-if="collection === 'all'">
            <a href="/">Home</a>
            <span>/</span>
            <a href="/rent-jewelry">Jewelry</a>
            <span>/</span>
            <a>{{ type[0].toUpperCase() + type.slice(1) }}</a>
          </div>
          <nav>
            <div class="pagination-products">
              <div class="chevron left">
                <a @click="previousPage()"></a>
              </div>
              &nbsp;
              <span>
                <span id="current-page"> {{ page }}</span>
                <span>&nbsp;/&nbsp;</span>
                <span id="total-pages">{{
                  totalPages === 0 ? 1 : totalPages
                }}</span>
                &nbsp;
              </span>
              <div class="chevron">
                <a @click="nextPage()"></a>
              </div>
            </div>
          </nav>
        </div>
        <div id="main-container" :class="{ 'diamonds-section': collection == 'diamond-jewelry-for-rent' }">
          <div class="filter-menu" v-if="collection === 'all'">
            <h4
              @click="isAccordionsVisible = !isAccordionsVisible"
              id="menu-heading"
            >
              Filter by
            </h4>
            <div
              class="accordions"
              v-bind:style="{ top: isAccordionsVisible ? '0' : '-120vh' }"
            >
              <svg
                id="#close-filter"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                stroke="black"
                stroke-width="3"
                stroke-linecap="round"
                @click="isAccordionsVisible = false"
              >
                <line x1="0" y1="0" x2="50" y2="50" />
                <line x1="50" y1="0" x2="0" y2="50" />
              </svg>
              <div
                v-for="panel in panelData"
                :key="panel.id"
                class="accordion"
                :id="panel.id"
              >
                <div class="panel panel-default">
                  <div
                    class="ac-title collapsed"
                    data-toggle="collapse"
                    :data-parent="`#` + panel.id"
                    :href="`#` + panel.href"
                  >
                    {{ panel.panelName }}
                  </div>
                </div>
                <ul class="panel-collapse collapse in" :id="panel.href">
                  <div class="filter-container">
                    <li
                      v-for="filterOption in panel.data"
                      :key="filterOption.id"
                    >
                      <input
                        type="radio"
                        :id="filterOption.id"
                        :name="panel.panelName.toLowerCase()"
                        v-model="$data[panel.panelName.toLowerCase()]"
                        :value="filterOption.text.toLowerCase()"
                        v-on:change="getProducts(true)"
                      />
                      <label :for="filterOption.id">
                        <p>{{ filterOption.text }}</p>
                        <img v-if="panel.panelName.toLowerCase() == 'colors'"
                          :class="['red', 'pink', 'yellow', 'orange'].includes(filterOption.id.split('-')[1]) ? 'img-saturated' : 
                                  ['black'].includes(filterOption.id.split('-')[1]) ? 'img-darker' :
                                  ['purple'].includes(filterOption.id.split('-')[1]) ? 'img-saturated-hue' : ''"
                          :src="colorsImagesLinks[filterOption.text.toLowerCase()]"/>
                      </label>
                    </li>
                  </div>
                </ul>
              </div>
              <div class="spacer" />
              <button id="clear-all" @click="clearAll()">Clear All</button>
              <div class="spacer" />
            </div>
          </div>
          <div id="products-container">
            <div
              class="product-thumb"
              v-for="product in products"
              :key="product.slug"
            >
              <div class="image-container">
                <a :href="`/rent-jewelry/` + product.slug">
                  <div class="image" :style="`background-image: url('${product.image_path}')`"></div>
                </a>
                <div class="details">
                  <a class="title" :href="`/rent-jewelry/` + product.slug">{{
                    product.title
                  }}</a>
                  <div
                    v-if="isUser"
                    class="favorite-action"
                    :id="'product-thub-favorite-' + plan"
                  >
                    <a
                      data-remote="true"
                      rel="nofollow"
                      :data-method="product.favourite ? `post` : `delete`"
                      :href="product.fav_href"
                      @click="favoriteHandler(product)"
                    >
                      <HeartIcon v-bind:isFav="product.favourite" />
                    </a>
                  </div>
                  <div class="sub">
                    <div v-if="plan === 'rbtd'">
                      <span>Rent for: $</span>
                      <span>{{ product.rental_price.cents }}</span>
                      <span>&nbsp;/ Per Day</span>
                    </div>
                    <div v-else>
                      <span>FREE WITH SUBSCRIPTION</span>
                    </div>
                    <span>Market Price:$</span>
                    <span id="market_price">{{
                      product.market_price.cents
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <div class="pagination-products" id="bottom-pagination">
            <div class="chevron left">
              <a @click="previousPage()"></a>
            </div>
            &nbsp;
            <span>
              <span id="current-page"> {{ page }}</span>
              <span>&nbsp;/&nbsp;</span>
              <span id="total-pages">{{
                totalPages === 0 ? 1 : totalPages
              }}</span>
              &nbsp;
            </span>
            <div class="chevron">
              <a @click="nextPage()"></a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import HeartIcon from "../../../../images/app/products/heartSvg.vue";
import { panelData } from "../../../../data/productsData.js";
import * as Toastr from "toastr";

export default {
  inherit: true,
  components: {
    HeartIcon,
  },
  props: {
    filter: { type: String },
    colorsImagesLinks: { type: Object },
  },
  data() {
    return {
      products: [],
      plan: this.$props.filter,
      type: "all jewelry",
      occasion: "all",
      collection: "all",
      collectionName: "",
      material: "all",
      gemstones: "all",
      style: "all",
      colors: "all",
      colorsImagesLinks: this.$props.colorsImagesLinks,
      isUser: false,
      panelData: panelData,
      isAccordionsVisible: false,
      page: 1,
      totalPages: 1,
      foundProductsCount: 1,
      per_page_variable: 9,
      isLoading: false,
      isFirstRender: true,
    };
  },
  computed: {
    bannerPlanId: function () {
      return this.plan === "rbtd" ? "rbtd-plan" : "sub-plan";
    },
  },
  methods: {
    nextPage: function () {
      if (
        this.page ===
          Math.ceil(this.foundProductsCount / this.per_page_variable) ||
        this.isLoading ||
        this.foundProductsCount === 0
      ) {
        return;
      }
      this.page = this.page + 1;
      this.getProducts();
    },
    previousPage: function () {
      if (this.page <= 1 || this.isLoading) {
        return;
      }
      this.page = this.page - 1;
      this.getProducts();
    },
    getProducts: function (isFilter) {
      if (isFilter) {
        this.page = 1;
      }
      this.isLoading = true;

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (this.isFirstRender) {
        urlParams.get("type") ? (this.type = urlParams.get("type")) : null;
        urlParams.get("collection")
          ? (this.collection = urlParams.get("collection"))
          : null;
        urlParams.get("occasion")
          ? (this.occasion = urlParams.get("occasion"))
          : null;
        urlParams.get("plan") ? (this.plan = urlParams.get("plan")) : "rbtd";
        urlParams.get("colors") ? (this.colors = urlParams.get("colors")) : null;

        this.occasion === "professional"
          ? (this.occasion = "professional/work")
          : null;
        this.occasion === "date-night" ? (this.occasion = "night out") : null;

        this.isFirstRender = false;
      }

      if (window !== undefined) {
        window.innerWidth < 1131
          ? (this.per_page_variable = 14)
          : (this.per_page_variable = 21);

        $.ajax({
          method: "GET",
          url: "/get-products",
          data: {
            per_page: this.per_page_variable,
            page: this.page,
            term: urlParams.get("term"),
            plan: this.plan,
            type: this.type,
            occasion: this.occasion,
            collections_slugs: this.collection,
            material: this.material,
            gemstones: this.gemstones,
            style: this.style,
            colors: this.colors,
          },
          success: (data) => {
            this.products = [];
            this.products = [...data.products];
            this.foundProductsCount = data.products_count;
            this.collectionName = data.collection_name;

            this.isUser = data.is_user;
            this.products.forEach((el) => {
              el.rental_price.cents = (el.rental_price.cents / 100).toFixed(2);
              el.market_price.cents = (el.market_price.cents / 100).toFixed(2);
              el.fav_href = "/favorites/" + el.slug;
            });
            this.isLoading = false;
            this.totalPages = Math.ceil(
              this.foundProductsCount / this.per_page_variable
            );
          },
          error: function (res) {
            Toastr.error(res.statusText, "Error fetching products.");
            this.isLoading = false;
          },
        });
      }
    },
    favoriteHandler: (product) => {
      product.favourite = !product.favourite;
    },
    clearAll: function () {
      this.plan = "rbtd";
      this.type = "all jewelry";
      this.occasion = "all";
      this.material = "all";
      this.gemstones = "all";
      this.style = "all";
      this.colors = "all";
      this.page = 1;
      this.getProducts();
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

