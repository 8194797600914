var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "transition",
        {
          attrs: { name: "slide-fade" },
          on: { "after-leave": _vm.afterLeave },
        },
        [
          _vm.show
            ? _c(
                "button",
                {
                  staticClass:
                    "btn btn-outline-secondary btn-animate btn-animate-side mx-1",
                  attrs: { type: "button" },
                  on: { click: _vm.deleteItem },
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "icon fas fa-times" }),
                    _vm._v(
                      "\n         " +
                        _vm._s(_vm.item.occasion.data.name) +
                        "\n       "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }