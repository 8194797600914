var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("new-absence", {
        attrs: {
          absence_create_path: _vm.absence_create_path,
          unavailable_dates: _vm.unavailable_dates,
        },
        on: { "absence-added": _vm.absenceAdded },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-5" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "transition-group",
              {
                tag: "table",
                staticClass: "table table-hover table-stripped",
                attrs: { name: "list" },
              },
              [
                _c(
                  "tbody",
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.absences, function (absence, index) {
                      return _c("tr", { key: absence.id }, [
                        _c("td", [_vm._v(_vm._s(absence.id))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(absence.start_date))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(absence.end_date))]),
                        _vm._v(" "),
                        _c("td", [
                          absence.order_id
                            ? _c("div", [
                                _vm._v(
                                  "\n            Order: #" +
                                    _vm._s(absence.order_id) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(absence.notes) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          !absence.order_id
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-small btn-danger",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteAbsence(
                                        absence.id,
                                        index
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Delete")]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th", [_vm._v("ID")]),
      _vm._v(" "),
      _c("th", [_vm._v("Start date")]),
      _vm._v(" "),
      _c("th", [_vm._v("End date")]),
      _vm._v(" "),
      _c("th", [_vm._v("Notes")]),
      _vm._v(" "),
      _c("th", [_vm._v("Action")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }