var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "show-modal-review",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("dl", [
              _c(
                "dt",
                [
                  _vm._v("Rating: "),
                  _vm._l(_vm.ratings, function (rating) {
                    return _c("i", {
                      staticClass: "fa-star",
                      class: _vm.starClass(rating),
                    })
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("dd"),
              _vm._v(" "),
              _c("dt", [_vm._v("Rented this for:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.occasion_text))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Product condition upon arrival:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.condition_text))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Was this product:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.satisfaction_text))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Did the product arrive on time?")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.on_time_text))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Would you rent from this vendor again?")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.rent_again_text))]),
              _vm._v(" "),
              _c("dt", [
                _vm._v("Would you recommend this jewelry to a friend?"),
              ]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.recommend_text))]),
              _vm._v(" "),
              _c("dt", [
                _vm._v("Anything you would like to add about this product:"),
              ]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.product_comment))]),
              _vm._v(" "),
              _c("dt", [
                _vm._v("Anything you would like to add about this vendor:"),
              ]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.userReview.vendor_comment))]),
              _vm._v(" "),
              _vm.userReview.file_variants
                ? _c("div", [
                    _c("img", {
                      staticClass: "img-thumbnail image-preview",
                      attrs: { src: _vm.userReview.file_variants.thumb },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v(" Your review")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("close x")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }