var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "hero" }, [
          _c(
            "button",
            {
              attrs: { id: "hiw-hero-player-button" },
              on: { click: _vm.showModal },
            },
            [_c("i", { staticClass: "icon far fa-play-circle" })]
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "description" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { id: "hiw-player-button" },
              on: { click: _vm.showModal },
            },
            [
              _c("span", [_vm._v("WATCH THE VIDEO TO FIND OUT")]),
              _vm._v(" "),
              _c("i", { staticClass: "icon far fa-play-circle" }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("HowItWorksVideoModal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isModalVisible,
            expression: "isModalVisible",
          },
        ],
        on: { close: _vm.closeModal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "stripes" }, [
      _c("div", { staticClass: "stripe-first" }),
      _vm._v(" "),
      _c("div", { staticClass: "stripe-second" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "desc" }, [
      _c("p", { staticClass: "title" }, [_vm._v("A SHORTCUT TO LUXURY")]),
      _vm._v(" "),
      _c("div", { staticClass: "pdesc" }, [
        _c("p", [
          _vm._v("Renting beautiful jewelry has "),
          _c("span", { staticClass: "pdesc__second-line--mobile" }, [
            _vm._v("never been "),
            _c("span", { staticClass: "pdesc__second-line--desktop" }, [
              _vm._v("this easy - or this fun."),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }