var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.sub_orders, function (sub_order) {
        return _c("sub-order", {
          key: sub_order.id,
          attrs: { sub_order: sub_order },
          on: {
            "update-order-item": _vm.updateOrderItem,
            "review-modal": _vm.reviewModal,
            "show-review-modal": _vm.showReviewModal,
            "update-sub-order": _vm.updateSubOrder,
          },
        })
      }),
      _vm._v(" "),
      _vm.sub_orders.length == 0
        ? _c("div", [_vm._v("\n    (No results.)\n  ")])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }