var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modal-register",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "svg",
              {
                staticClass: "close",
                attrs: {
                  "data-dismiss": "modal",
                  height: "50",
                  "stroke-linecap": "round",
                  "stroke-width": "3",
                  stroke: "black",
                  viewBox: "0 0 50 50",
                  width: "50",
                },
              },
              [
                _c("line", { attrs: { x1: "0", x2: "50", y1: "0", y2: "50" } }),
                _vm._v(" "),
                _c("line", { attrs: { x1: "50", x2: "0", y1: "0", y2: "50" } }),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.status !== "complete"
            ? _c(
                "div",
                { staticClass: "signup-method-wrapper" },
                [
                  _c("FacebookButton", {
                    attrs: { "modal-name": "register", "with-icon": true },
                  }),
                  _vm._v(" "),
                  _vm._m(2),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm.status === "complete"
              ? _c("div", { staticClass: "text-center p-4" }, [
                  _c("h5", { staticClass: "register-success" }, [
                    _vm._v("Success!"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n            Your account has been registered\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "pending"
              ? _c("div", [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-title",
                        attrs: { for: "register-first-name" },
                      },
                      [_vm._v("FIRST NAME")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.first_name,
                          expression: "first_name",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.first_name },
                      attrs: {
                        type: "text",
                        id: "register-first-name",
                        placeholder: "",
                      },
                      domProps: { value: _vm.first_name },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.register.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.first_name = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.first_name
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.first_name.join(", ")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-title",
                        attrs: { for: "register-last-name" },
                      },
                      [_vm._v("LAST NAME")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.last_name,
                          expression: "last_name",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.last_name },
                      attrs: {
                        type: "text",
                        id: "register-last-name",
                        placeholder: "",
                      },
                      domProps: { value: _vm.last_name },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.register.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.last_name = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.last_name
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.last_name.join(", ")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-title",
                        attrs: { for: "register-email" },
                      },
                      [_vm._v("EMAIL")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.email },
                      attrs: {
                        type: "email",
                        id: "register-email",
                        placeholder: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.register.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.email
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.email.join(", ")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-title",
                        attrs: { for: "register-password" },
                      },
                      [_vm._v("PASSWORD")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.password },
                      attrs: {
                        type: "password",
                        id: "register-password",
                        placeholder: "",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.register.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.password
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.password.join(", ")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.recaptchaSuccessfull,
                  expression: "!recaptchaSuccessfull",
                },
              ],
              staticClass: "recaptcha-wrapper",
            },
            [
              _c("vue-recaptcha", {
                ref: "recaptcha",
                attrs: { loadRecaptchaScript: true, sitekey: _vm.sitekey },
                on: { verify: _vm.onVerify, expired: _vm.onExpired },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.status !== "complete"
            ? _c(
                "button",
                {
                  staticClass: "submit-btn",
                  attrs: { type: "submit", id: "create-account" },
                  on: { click: _vm.register },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.registerButtonText) + "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.status !== "complete"
            ? _c(
                "button",
                {
                  staticClass: "login-acc-button",
                  attrs: { type: "submit" },
                  on: { click: _vm.showLoginModal },
                },
                [
                  _vm._v(
                    "\n        Already have an account? Login here.\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.status === "complete"
            ? _c(
                "button",
                {
                  staticClass: "submit-btn",
                  attrs: { "data-dismiss": "modal" },
                },
                [_vm._v("\n        OK\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.status === "complete"
            ? _c("p", { staticClass: "spam-notice text-center p4" }, [
                _vm._v(
                  "\n        If you do not see an email from Trejours in your inbox,"
                ),
                _c("br"),
                _vm._v(" please\n        check your spam folder.\n      "),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo-container text-center mb-4" }, [
      _c("div", { staticClass: "logo" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-3 text-center" }, [
      _c("h5", { staticClass: "welcome-title" }, [
        _vm._v("Welcome to Trejours!"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-block google-btn",
        attrs: {
          href: "/accounts/auth/google_oauth2",
          "data-method": "post",
          rel: "nofollow",
        },
      },
      [
        _c("div", { staticClass: "google-ico" }),
        _vm._v("\n          SIGN UP WITH GOOGLE\n        "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n            You will receive "),
      _c("br"),
      _c("span", { staticClass: "underline" }, [
        _vm._v("a confirmation email."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n            Please click on the link in the email"),
      _c("br"),
      _vm._v(" to activate your account.\n          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }