<template>
  <div
    class="modal fade"
    id="modal-login"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-image"></div>
      <div class="modal-content">
        <div class="close-cta">
          <svg
            class="close"
            data-dismiss="modal"
            height="25"
            stroke-linecap="round"
            stroke-width="3"
            stroke="black"
            viewBox="0 0 50 50"
            width="25"
          >
            <line x1="0" x2="50" y1="0" y2="50"></line>
            <line x1="50" x2="0" y1="0" y2="50"></line>
          </svg>
        </div>
        <div class="modal-body">
          <div class="logo text-center">
            <img src="~images/app/login/logo.svg" alt="" />
          </div>
          <h4 class="text-center mb-4">Welcome to Trejours</h4>

          <div
            class="text-center p-4 login-modal-success"
            v-if="status === 'complete'"
          >
            <h5>Success!</h5>
            <p>
              You have successfully authenticated,
              <br />
              the page will be refreshed in a second.
            </p>
          </div>

          <div v-if="status === 'pending'">
            <div class="signup-method-wrapper">
              <FacebookButton
                :with-icon=true
                :modal-name="'login'"
              />
              <a
                href="/accounts/auth/google_oauth2"
                class="btn btn-block google-btn"
                data-method="post"
                rel="nofollow"
              >
                <div class="google-ico"></div>
                SIGN IN WITH GOOGLE
              </a>
            </div>
            <div class="form-group login-field">
              <label for="login-email">EMAIL</label>
              <input
                type="email"
                class="form-control"
                id="login-email"
                placeholder=""
                v-model="email"
                @keyup.enter="login"
              />
            </div>

            <div class="form-group login-field">
              <label for="login-password">PASSWORD</label>
              <input
                type="password"
                class="form-control"
                id="login-password"
                placeholder=""
                v-model="password"
                @keyup.enter="login"
              />
            </div>
            <div class="submenu">
              <div class="remember">
                <input
                  v-model="rememberMe"
                  type="checkbox"
                  id="scales"
                  name="scales"
                />
                <label for="scales">Remember Me</label>
              </div>
              <a href="javascript:;" @click="showPasswordModal"
                >Forgot Password?</a
              >
            </div>

            <div class="recaptcha-wrapper" v-show="!recaptchaSuccessfull">
              <vue-recaptcha
                :loadRecaptchaScript="true"
                ref="recaptcha"
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="sitekey"
              >
              </vue-recaptcha>
            </div>

            <div class="login-cta-wrapper">
              <button type="submit" class="login-button" @click="login">
                CONTINUE
              </button>
              <button
                type="submit"
                class="create-acc-button"
                @click="showRegisterModal"
              >
                Register an account.
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Toastr from "toastr";
import VueRecaptcha from "vue-recaptcha";
import FacebookButton from './facebook_button'
export default {
  inherit: true,
  props: {
    path: { type: String, required: true },
    vendor_sing_up_path: { type: String, required: true },
    recaptcha_path: { type: String, required: true },
    sitekey: { type: String, required: true },
  },
  components: {
    VueRecaptcha: VueRecaptcha,
    FacebookButton: FacebookButton,
  },
  data: function () {
    return {
      email: "",
      password: "",
      status: "pending",
      recaptchaChallange: null,
      recaptchaSuccessfull: false,
      rememberMe: null,
    };
  },
  methods: {
    login() {
      if (!this.recaptchaSuccessfull) {
        Toastr.error(
          "Please complete anti-spam verification",
          "Registration error"
        );
        return;
      }

      var that = this;

      $.ajax({
        method: "POST",
        data: {
          account: {
            email: that.email,
            password: that.password,
            rememberMe: that.rememberMe,
          },
        },
        url: that.path,
        success: function (data) {
          that.status = "complete";
          Toastr.success("Log in successfull!", "Authentication success");
          setTimeout(function () {
            location.href = data.redirect_url;
          }, 2000);
        },
        error: function (data) {
          Toastr.error(data.responseJSON.error, "Authentication error");
        },
      });
    },
    showRegisterModal() {
      $("#modal-login").modal("hide");
      $("#modal-register").modal("show");
    },
    showPasswordModal() {
      $("#modal-login").modal("hide");
      $("#modal-password").modal("show");
    },
    onVerify: function (response) {
      this.recaptchaChallange = response;
      this.recaptchaSuccessfull = true;
    },
    onExpired: function () {
      Toastr.error(
        "Anti-spam verification expired. Please verify again.",
        "Registration error"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#modal-login {
  overflow: auto !important;
}

.modal-dialog {
  display: flex;
  justify-content: center;

  .modal-content {
    display: flex;
    height: 855px;
    width: 495px;
    border-radius: 0px;
    border: none;
    background: #b7c3bd;
    padding: 0 60px;
    margin: 0;

    @media (max-width: 813px) {
      height: fit-content;
      width: 100%;
      padding: 0 18px;
    }

    .modal-body {
      margin-top: 60px;
      @media (max-width: 813px) {
        margin-top: 20px;
      }
      .logo {
        margin-bottom: 1em;
        img {
          height: 70px;
          width: auto;
        }
      }
      h4 {
        font-family: Utile Display Book;
        font-style: normal;
        font-weight: normal;
        font-size: 23px;
        line-height: 26px;
        color: #000000;
        width: 200px;
        padding-bottom: 6.25px;
        border-bottom: 1px solid #707070;
        margin: 0 auto;
      }
    }
  }
  .modal-image {
    background: url("~images/app/login/modal-login-background.webp");
    min-width: 368px;
    height: 855px;

    @media (max-width: 880px) {
      min-width: 300px;
    }
    @media (max-width: 813px) {
      display: none;
    }
  }

  ::v-deep .fb-ico,
  .google-ico {
    width: 24px;
    height: 22px;
    background: url("~images/app/login/fb-icon.svg");
    background-position: center;
    background-size: cover;
    margin-right: 0.5em;
    @media (max-width: 813px) {
      margin-right: 8px;
      height: 20px;
      width: 22px;
    }
  }

  .google-ico {
    background: url("~images/app/login/google-icon.svg");
    background-position: center;
    background-size: cover;
  }

  .google-btn,
  .fb-btn {
    width: 230px;
    height: 34px;
    background: #d3d3d3;
    text-align: center;
    font-family: Utile;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    margin: 0 auto 15.5px;

    @media (max-width: 813px) {
      width: 179px;
      height: 30px;
      font-size: 11px;
    }
  }

  .fb-btn {
    margin-top: 60px;
    @media (max-width: 813px) {
      margin-top: 24px;
    }
  }
  .google-btn {
    margin-bottom: 38px;

    @media (max-width: 813px) {
      margin-bottom: 20px;
    }
  }

  .login-field {
    margin-bottom: 8px;
    label {
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 12.5px;
      line-height: 14px;
    }
    input {
      height: 35px;
    }
  }

  .submenu {
    display: flex;
    justify-content: space-between;
    font-family: Utile;
    font-style: normal;
    font-weight: normal;
    font-size: 12.5px;
    line-height: 14px;
    margin-bottom: 52px;

    @media (max-width: 813px) {
      margin-bottom: 20px;
    }
  }

  .recaptcha-wrapper {
    margin-left: 20px;
    padding-right: 50px;
    @media (max-width: 813px) {
      margin-left: 0;
    }
    @media (max-width: 390px) {
      margin-left: -20px;
    }
  }

  .login-cta-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 813px) {
      margin-top: 20px;
    }
    .login-button {
      width: 187px;
      height: 37px;
      background: #343333;
      color: white;
      border: none;
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 30px;
      &:hover {
        background: #7e7d7d;
        color: black;
      }
      @media (max-width: 813px) {
        margin-bottom: 10px;
      }
    }

    .create-acc-button {
      background: none;
      border: none;
      border-bottom: solid 1px #7e7d7d;
      padding-bottom: 4px;
      font-family: Utile;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.submenu {
  a:hover {
    color: black;
    opacity: 0.7;
  }
}

.login-modal-success {
  h5 {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 26px;
    color: #000000;
  }

  p {
    font-family: Utile Display Book;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
  }
}

.close-cta {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
