var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hiw-video-player", attrs: { id: "hiw-modal-video" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("span", { on: { click: _vm.closeModal } }, [
        _c("i", { staticClass: "icon fas fa-times" }),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "video",
      { attrs: { width: "320", height: "240", controls: "", id: "hiw-video" } },
      [
        _c("source", {
          attrs: {
            src: require("../../../../videos/hiw-video.mp4"),
            type: "video/mp4",
          },
        }),
        _vm._v("\n    Your browser does not support the video tag.\n  "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }