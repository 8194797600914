var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", { staticClass: "dropzone", attrs: { action: _vm.createpath } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }