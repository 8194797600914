var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modal-instructions",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("h4", { staticClass: "text-center mb-4" }, [
              _vm._v("Resend confirmation instructions"),
            ]),
            _vm._v(" "),
            _vm.status === "complete"
              ? _c("div", { staticClass: "text-center p-4" }, [
                  _c("h5", [_vm._v("Success!")]),
                  _vm._v(" "),
                  _vm._m(1),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "pending"
              ? _c(
                  "div",
                  [
                    _c("FacebookButton", {
                      attrs: {
                        "modal-name": "instructions",
                        "with-icon": false,
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "instructions-email" } }, [
                        _vm._v("Email address"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.email },
                        attrs: {
                          type: "email",
                          id: "instructions-email",
                          placeholder: "Enter email",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.resendInstructions.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.email
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.errors.email.join(", "))),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit" },
                        on: { click: _vm.resendInstructions },
                      },
                      [_vm._v("Resend confirmation instructions")]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "my-3" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.showLoginModal },
                        },
                        [_vm._v("Log in")]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.showRegisterModal },
                        },
                        [_vm._v("Create account")]
                      ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:;" },
                          on: { click: _vm.showPasswordModal },
                        },
                        [_vm._v("Forgot your password?")]
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("close x")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            You will receive an email with instructions\n            "
      ),
      _c("br"),
      _vm._v(
        "\n            for how to confirm your email address in a few minutes.\n          "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-3 text-center" }, [
      _c("h5", [_vm._v("or")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }