var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "product-page" }, [
      _vm.collection === "all" && _vm.type === "all jewelry"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: _vm.bannerPlanId } },
            [
              _vm.bannerPlanId != "sub-plan"
                ? _c("div", { staticClass: "banner-details" }, [
                    _c("h5", [_vm._v("RENT BY THE DAY")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v("RENT DESIGNER JEWELRY FOR YOUR NEXT OCCASION"),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.bannerPlanId === "sub-plan"
                ? _c("div", { staticClass: "banner-details" }, [
                    _c("h5", [_vm._v("SUBSCRIPTION BOX")]),
                    _vm._v(" "),
                    _c("p", [_vm._v("RENT DESIGNER JEWELRY FOR ANY OCCASION")]),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.collection != "all"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: "collection-banner" } },
            [
              _c("div", { staticClass: "banner-details" }, [
                _c("h5", [_vm._v("FEATURED COLLECTION")]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(_vm.collectionName))]),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "necklaces" && _vm.bannerPlanId != "sub-plan"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: "necklaces-rbtd-banner" } },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "necklaces" && _vm.bannerPlanId === "sub-plan"
        ? _c(
            "div",
            {
              staticClass: "banner",
              attrs: { id: "necklaces-subscription-banner" },
            },
            [_vm._m(1)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "earrings" && _vm.bannerPlanId != "sub-plan"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: "earrings-rbtd-banner" } },
            [_vm._m(2)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "earrings" && _vm.bannerPlanId === "sub-plan"
        ? _c(
            "div",
            {
              staticClass: "banner",
              attrs: { id: "earrings-subscription-banner" },
            },
            [_vm._m(3)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "bracelets" && _vm.bannerPlanId != "sub-plan"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: "bracelets-rbtd-banner" } },
            [_vm._m(4)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "bracelets" && _vm.bannerPlanId === "sub-plan"
        ? _c(
            "div",
            {
              staticClass: "banner",
              attrs: { id: "bracelets-subscription-banner" },
            },
            [_vm._m(5)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "rings" && _vm.bannerPlanId != "sub-plan"
        ? _c(
            "div",
            { staticClass: "banner", attrs: { id: "rings-rbtd-banner" } },
            [_vm._m(6)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "rings" && _vm.bannerPlanId === "sub-plan"
        ? _c(
            "div",
            {
              staticClass: "banner",
              attrs: { id: "rings-subscription-banner" },
            },
            [_vm._m(7)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.collection == "diamond-jewelry-for-rent"
        ? _c("div", { staticClass: "diamonds-collection" }, [
            _c("h3", [
              _vm._v("RENT DIAMOND JEWELRY FOR YOUR SPECIAL OCCASION"),
            ]),
            _vm._v(" "),
            _c("div"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Trejours offers you an affordable new to wear designer diamond jewelry to any special occasion.\n        Simply choose the jewelry you like, reserve it for the date you want, and you'll get it shipped right to your doorstep.\n        Experience luxury like never before - without commitment.\n      "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "products-wrapper" }, [
        _c("div", { staticClass: "navigation" }, [
          _vm.collection === "all"
            ? _c("div", { staticClass: "nav-box" }, [
                _c("a", { attrs: { href: "/" } }, [_vm._v("Home")]),
                _vm._v(" "),
                _c("span", [_vm._v("/")]),
                _vm._v(" "),
                _c("a", { attrs: { href: "/rent-jewelry" } }, [
                  _vm._v("Jewelry"),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v("/")]),
                _vm._v(" "),
                _c("a", [
                  _vm._v(_vm._s(_vm.type[0].toUpperCase() + _vm.type.slice(1))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("nav", [
            _c("div", { staticClass: "pagination-products" }, [
              _c("div", { staticClass: "chevron left" }, [
                _c("a", {
                  on: {
                    click: function ($event) {
                      return _vm.previousPage()
                    },
                  },
                }),
              ]),
              _vm._v("\n             \n            "),
              _c("span", [
                _c("span", { attrs: { id: "current-page" } }, [
                  _vm._v(" " + _vm._s(_vm.page)),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(" / ")]),
                _vm._v(" "),
                _c("span", { attrs: { id: "total-pages" } }, [
                  _vm._v(_vm._s(_vm.totalPages === 0 ? 1 : _vm.totalPages)),
                ]),
                _vm._v("\n               \n            "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "chevron" }, [
                _c("a", {
                  on: {
                    click: function ($event) {
                      return _vm.nextPage()
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: {
              "diamonds-section": _vm.collection == "diamond-jewelry-for-rent",
            },
            attrs: { id: "main-container" },
          },
          [
            _vm.collection === "all"
              ? _c("div", { staticClass: "filter-menu" }, [
                  _c(
                    "h4",
                    {
                      attrs: { id: "menu-heading" },
                      on: {
                        click: function ($event) {
                          _vm.isAccordionsVisible = !_vm.isAccordionsVisible
                        },
                      },
                    },
                    [_vm._v("\n            Filter by\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "accordions",
                      style: { top: _vm.isAccordionsVisible ? "0" : "-120vh" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            id: "#close-filter",
                            width: "50",
                            height: "50",
                            viewBox: "0 0 50 50",
                            stroke: "black",
                            "stroke-width": "3",
                            "stroke-linecap": "round",
                          },
                          on: {
                            click: function ($event) {
                              _vm.isAccordionsVisible = false
                            },
                          },
                        },
                        [
                          _c("line", {
                            attrs: { x1: "0", y1: "0", x2: "50", y2: "50" },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: { x1: "50", y1: "0", x2: "0", y2: "50" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.panelData, function (panel) {
                        return _c(
                          "div",
                          {
                            key: panel.id,
                            staticClass: "accordion",
                            attrs: { id: panel.id },
                          },
                          [
                            _c("div", { staticClass: "panel panel-default" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "ac-title collapsed",
                                  attrs: {
                                    "data-toggle": "collapse",
                                    "data-parent": `#` + panel.id,
                                    href: `#` + panel.href,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(panel.panelName) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass: "panel-collapse collapse in",
                                attrs: { id: panel.href },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "filter-container" },
                                  _vm._l(panel.data, function (filterOption) {
                                    return _c("li", { key: filterOption.id }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$data[
                                                panel.panelName.toLowerCase()
                                              ],
                                            expression:
                                              "$data[panel.panelName.toLowerCase()]",
                                          },
                                        ],
                                        attrs: {
                                          type: "radio",
                                          id: filterOption.id,
                                          name: panel.panelName.toLowerCase(),
                                        },
                                        domProps: {
                                          value:
                                            filterOption.text.toLowerCase(),
                                          checked: _vm._q(
                                            _vm.$data[
                                              panel.panelName.toLowerCase()
                                            ],
                                            filterOption.text.toLowerCase()
                                          ),
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              _vm.$set(
                                                _vm.$data,
                                                panel.panelName.toLowerCase(),
                                                filterOption.text.toLowerCase()
                                              )
                                            },
                                            function ($event) {
                                              return _vm.getProducts(true)
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: filterOption.id } },
                                        [
                                          _c("p", [
                                            _vm._v(_vm._s(filterOption.text)),
                                          ]),
                                          _vm._v(" "),
                                          panel.panelName.toLowerCase() ==
                                          "colors"
                                            ? _c("img", {
                                                class: [
                                                  "red",
                                                  "pink",
                                                  "yellow",
                                                  "orange",
                                                ].includes(
                                                  filterOption.id.split("-")[1]
                                                )
                                                  ? "img-saturated"
                                                  : ["black"].includes(
                                                      filterOption.id.split(
                                                        "-"
                                                      )[1]
                                                    )
                                                  ? "img-darker"
                                                  : ["purple"].includes(
                                                      filterOption.id.split(
                                                        "-"
                                                      )[1]
                                                    )
                                                  ? "img-saturated-hue"
                                                  : "",
                                                attrs: {
                                                  src: _vm.colorsImagesLinks[
                                                    filterOption.text.toLowerCase()
                                                  ],
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "spacer" }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          attrs: { id: "clear-all" },
                          on: {
                            click: function ($event) {
                              return _vm.clearAll()
                            },
                          },
                        },
                        [_vm._v("Clear All")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "spacer" }),
                    ],
                    2
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "products-container" } },
              _vm._l(_vm.products, function (product) {
                return _c(
                  "div",
                  { key: product.slug, staticClass: "product-thumb" },
                  [
                    _c("div", { staticClass: "image-container" }, [
                      _c(
                        "a",
                        { attrs: { href: `/rent-jewelry/` + product.slug } },
                        [
                          _c("div", {
                            staticClass: "image",
                            style: `background-image: url('${product.image_path}')`,
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "details" }, [
                        _c(
                          "a",
                          {
                            staticClass: "title",
                            attrs: { href: `/rent-jewelry/` + product.slug },
                          },
                          [_vm._v(_vm._s(product.title))]
                        ),
                        _vm._v(" "),
                        _vm.isUser
                          ? _c(
                              "div",
                              {
                                staticClass: "favorite-action",
                                attrs: {
                                  id: "product-thub-favorite-" + _vm.plan,
                                },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      "data-remote": "true",
                                      rel: "nofollow",
                                      "data-method": product.favourite
                                        ? `post`
                                        : `delete`,
                                      href: product.fav_href,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.favoriteHandler(product)
                                      },
                                    },
                                  },
                                  [
                                    _c("HeartIcon", {
                                      attrs: { isFav: product.favourite },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "sub" }, [
                          _vm.plan === "rbtd"
                            ? _c("div", [
                                _c("span", [_vm._v("Rent for: $")]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(product.rental_price.cents)),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(" / Per Day")]),
                              ])
                            : _c("div", [
                                _c("span", [_vm._v("FREE WITH SUBSCRIPTION")]),
                              ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Market Price:$")]),
                          _vm._v(" "),
                          _c("span", { attrs: { id: "market_price" } }, [
                            _vm._v(_vm._s(product.market_price.cents)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]
        ),
        _vm._v(" "),
        _c("nav", [
          _c(
            "div",
            {
              staticClass: "pagination-products",
              attrs: { id: "bottom-pagination" },
            },
            [
              _c("div", { staticClass: "chevron left" }, [
                _c("a", {
                  on: {
                    click: function ($event) {
                      return _vm.previousPage()
                    },
                  },
                }),
              ]),
              _vm._v("\n           \n          "),
              _c("span", [
                _c("span", { attrs: { id: "current-page" } }, [
                  _vm._v(" " + _vm._s(_vm.page)),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(" / ")]),
                _vm._v(" "),
                _c("span", { attrs: { id: "total-pages" } }, [
                  _vm._v(_vm._s(_vm.totalPages === 0 ? 1 : _vm.totalPages)),
                ]),
                _vm._v("\n             \n          "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "chevron" }, [
                _c("a", {
                  on: {
                    click: function ($event) {
                      return _vm.nextPage()
                    },
                  },
                }),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("FEATURED COLLECTION")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER NECKLACES FOR YOUR NEXT OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("SUBSCRIPTION BOX")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER NECKLACES FOR ANY OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("FEATURED COLLECTION")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER EARRINGS FOR YOUR NEXT OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("SUBSCRIPTION BOX")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER EARRINGS FOR ANY OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("FEATURED COLLECTION")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER BRACELETS FOR YOUR NEXT OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("SUBSCRIPTION BOX")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER BRACELETS FOR ANY OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("FEATURED COLLECTION")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER RINGS FOR YOUR NEXT OCCASION")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-details" }, [
      _c("h5", [_vm._v("SUBSCRIPTION BOX")]),
      _vm._v(" "),
      _c("p", [_vm._v("RENT DESIGNER RINGS FOR ANY OCCASION")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }