var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb-3" }, [
    _vm.shopping_mode != "subscription"
      ? _c("div", { staticClass: "form-inline" }, [
          _c(
            "div",
            {
              staticClass: "input-group mb-0 flex-grow-1",
              attrs: { id: "promo-input-btn" },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.code,
                    expression: "code",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  name: "coupon_code",
                  placeholder: "Enter promo code",
                },
                domProps: { value: _vm.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.code = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  { staticClass: "btn promo-btn", on: { click: _vm.submit } },
                  [_vm._v("Apply")]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.subscriptionDiscountMsg
      ? _c("h4", { staticClass: "my-3 text-center" }, [
          _vm._v("\n    " + _vm._s(_vm.subscriptionDiscountMsg) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }