import { render, staticRenderFns } from "./item.vue?vue&type=template&id=7cc50eee"
import script from "./item.vue?vue&type=script&lang=js"
export * from "./item.vue?vue&type=script&lang=js"
import style0 from "./item.vue?vue&type=style&index=0&id=7cc50eee&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cc50eee')) {
      api.createRecord('7cc50eee', component.options)
    } else {
      api.reload('7cc50eee', component.options)
    }
    module.hot.accept("./item.vue?vue&type=template&id=7cc50eee", function () {
      api.rerender('7cc50eee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/client/javascripts/admin/components/categories/item.vue"
export default component.exports