var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modal-password",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
              },
              [
                _c(
                  "span",
                  {
                    attrs: { "aria-hidden": "true" },
                    on: { click: _vm.closeModal },
                  },
                  [_vm._v("X")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("h4", { staticClass: "text-center mb-2" }, [
              _vm._v("Forgot your password?"),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.status === "complete"
              ? _c("div", { staticClass: "text-center p-4" }, [
                  _c("h5", [_vm._v("Success!")]),
                  _vm._v(" "),
                  _vm._m(0),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "pending"
              ? _c("div", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control",
                      class: { "is-invalid": _vm.errors.email },
                      attrs: {
                        type: "email",
                        id: "password-email",
                        placeholder: "Enter email",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        keyup: [
                          function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.resetPassword.apply(null, arguments)
                          },
                          _vm.clearErrors,
                        ],
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.email
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.email.join(", ")) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "button-container" }, [
                    _c(
                      "button",
                      {
                        staticClass: "send-password-button",
                        attrs: { type: "submit" },
                        on: { click: _vm.resetPassword },
                      },
                      [
                        _vm._v(
                          "\n              SEND NEW PASSWORD\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "signup-method-wrapper" },
                    [
                      _c("FacebookButton", {
                        attrs: { "modal-name": "password", "with-icon": true },
                      }),
                      _vm._v(" "),
                      _vm._m(2),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper", on: { click: _vm.closeModal } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            You will receive an email with instructions\n            "
      ),
      _c("br"),
      _vm._v(
        "\n            on how to reset your password in a few minutes.\n          "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-2 text-center" }, [
      _c("h5", [_vm._v("or")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-block google-btn",
        attrs: {
          href: "/accounts/auth/google_oauth2",
          "data-method": "post",
          rel: "nofollow",
        },
      },
      [
        _c("div", { staticClass: "google-ico" }),
        _vm._v("\n              SIGN UP WITH GOOGLE\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }