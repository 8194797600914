var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calendar" }, [
    _c(
      "div",
      { staticClass: "calendar-body" },
      [
        _c("flat-pickr", {
          attrs: { config: _vm.config, placeholder: "Select a date" },
          on: { "on-change": _vm.setRange },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v
            },
            expression: "date",
          },
        }),
        _vm._v(" "),
        _vm._m(0),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "calendar-footer" }, [
      _vm.isUserAccount
        ? _c("div", { staticClass: "calendar-buttons" }, [
            _vm.isVariantInOrder
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary change-date",
                    attrs: { id: "change-date-" + _vm.productId },
                    on: { click: _vm.updateDatesForOrder },
                  },
                  [_vm._v("\n        Change Date\n      ")]
                )
              : _vm.order.limit_reached
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-limit-reached",
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: "Remove items from your cart to add new",
                    },
                  },
                  [_vm._v("\n        Limit reached\n      ")]
                )
              : !_vm.canAddToCart
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title:
                        "Product not allowed for " +
                        _vm.shopping_mode +
                        " shopping mode",
                    },
                  },
                  [_vm._v("\n        ADD TO CART\n      ")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-primary rbtd-added-button",
                    on: { click: _vm.addToCart },
                  },
                  [_vm._v("\n        ADD TO CART\n      ")]
                ),
            _vm._v(" "),
            _vm.isVariantInOrder
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-danger px-3",
                    attrs: {
                      id: "remove-from-cart-" + _vm.productId,
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: "Remove from cart",
                    },
                    on: { click: _vm.removeFromCart },
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "calendar-buttons" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.requireLogin },
              },
              [_vm._v("\n        ADD TO CART\n      ")]
            ),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calendar-popup" }, [
      _c("p", { attrs: { id: "calendar-close" } }, [_vm._v("X")]),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-legend" }, [
        _c("span", { staticClass: "receive" }, [_vm._v(" Receive ")]),
        _vm._v(" "),
        _c("span", { staticClass: "return" }, [_vm._v(" Return ")]),
        _vm._v(" "),
        _c("span", { staticClass: "unavailable" }, [_vm._v(" Unavailable ")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-legend-weekdays" }, [
        _vm._v("Delivery is NOT available on weekends"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "calendar-texts" }, [
        _c("h2", { staticClass: "rbtd" }, [_vm._v("RENT BY THE DAY")]),
        _vm._v(" "),
        _c("p", { staticClass: "rbtd-text" }, [
          _vm._v("Up to 3 pieces for 1 week."),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "rbtd-text" }, [
          _vm._v("ONE date for your chosen pieces"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }