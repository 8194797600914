var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "step-header" }, [
      _vm._v("\n    wear new jewelry every month. like a boss.\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "image-wrapper" }, [
      _c("img", {
        attrs: { src: require("images/app/subscriptions/step-1.webp") },
        on: { click: _vm.nextStep },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "text-wrapper" }, [
        _c("div", { staticClass: "text" }, [
          _c("h1", [_vm._v("every month you get")]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.nextStep },
            },
            [_vm._v("Got it. Continue")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n          pick 3 pieces of fine jewelry\n          "),
      _c("br"),
      _vm._v("\n          experiment with new designer styles\n          "),
      _c("br"),
      _vm._v("\n          wear new or hip vintage jewelry\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }