var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "form-group" }, [
      _c("label", { staticClass: "bmd-label-floating" }, [
        _vm._v("Search occasions"),
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "searchOccasions",
        staticClass: "form-control",
        attrs: { name: "searchOccasions", type: "text" },
        on: { keyup: _vm.search },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "list-group" },
      _vm._l(_vm.items, function (item) {
        return _c("item", {
          key: item.id,
          attrs: {
            item: item,
            addpath: _vm.addpath,
            createpath: _vm.createpath,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }