var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "step-header" }, [
      _vm._v(
        "\n    create a free trejours account. we don't talk to strangers.\n  "
      ),
    ]),
    _vm._v(" "),
    !_vm.account.persisted
      ? _c("div", { staticClass: "fields" }, [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "fb-signin-button",
                {
                  attrs: { params: _vm.fbSignInParams },
                  on: {
                    success: _vm.onSignInSuccess,
                    error: _vm.onSignInError,
                  },
                },
                [_vm._v("\n        Sign in with Facebook\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "text-center info-text" }, [
            _vm._v("\n      Or fill in this form below\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fields-wrapper" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "register-first-name" } }, [
                    _vm._v("First name"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.account.first_name,
                        expression: "account.first_name",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.errors.first_name },
                    attrs: {
                      type: "text",
                      id: "register-first-name",
                      placeholder: "First name",
                    },
                    domProps: { value: _vm.account.first_name },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.register.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.account, "first_name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.first_name
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.errors.first_name.join(", "))),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "register-last-name" } }, [
                    _vm._v("Last name"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.account.last_name,
                        expression: "account.last_name",
                      },
                    ],
                    staticClass: "form-control",
                    class: { "is-invalid": _vm.errors.last_name },
                    attrs: {
                      type: "text",
                      id: "register-last-name",
                      placeholder: "Last name",
                    },
                    domProps: { value: _vm.account.last_name },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.register.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.account, "last_name", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.last_name
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.errors.last_name.join(", "))),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "register-email" } }, [
                _vm._v("Email address"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.email,
                    expression: "account.email",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.errors.email },
                attrs: {
                  type: "email",
                  id: "register-email",
                  placeholder: "Enter email",
                },
                domProps: { value: _vm.account.email },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.register.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.account, "email", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.email
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(_vm._s(_vm.errors.email.join(", "))),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "register-password" } }, [
                _vm._v("Password"),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.password,
                    expression: "account.password",
                  },
                ],
                staticClass: "form-control",
                class: { "is-invalid": _vm.errors.password },
                attrs: {
                  type: "password",
                  id: "register-password",
                  placeholder: "Password",
                  autocomplete: "new-password",
                },
                domProps: { value: _vm.account.password },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.register.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.account, "password", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errors.password
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(_vm._s(_vm.errors.password.join(", "))),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.recaptchaSuccessfull,
                    expression: "!recaptchaSuccessfull",
                  },
                ],
                staticClass: "recaptcha-wrapper",
              },
              [
                _c("vue-recaptcha", {
                  ref: "recaptcha",
                  attrs: { loadRecaptchaScript: true, sitekey: _vm.sitekey },
                  on: { verify: _vm.onVerify, expired: _vm.onExpired },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { type: "submit", id: "create-account" },
                on: { click: _vm.register },
              },
              [_vm._v("Create account")]
            ),
          ]),
        ])
      : _c("div", { staticClass: "fields" }, [
          _c("div", { staticClass: "text-center" }, [
            _c("p", [_vm._v("You're currently logged in as:")]),
            _vm._v(" "),
            _c("h4", [_vm._v(_vm._s(_vm.account.name))]),
            _vm._v(" "),
            _c("h5", [_vm._v(_vm._s(_vm.account.email))]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }