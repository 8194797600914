var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "btn btn-block fb-btn",
      attrs: { href: "/accounts/auth/facebook", "data-method": "post" },
    },
    [
      _vm.withIcon ? _c("div", { staticClass: "fb-ico" }) : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.buttonText) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }