var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "a",
      { attrs: { id: "persona" }, on: { click: _vm.togglePersonaModal } },
      [_vm._v("\n    Click here to scan\n  ")]
    ),
    _vm._v(" "),
    _vm.modalVisible
      ? _c("div", { attrs: { id: "skip-persona-modal" } }, [
          _c("div", { staticClass: "container" }, [
            _c("h2", [_vm._v("WARNING!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "You have not completed the Drivers License / State ID Scan."
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("a", { on: { click: _vm.scanNow } }, [_vm._v("SCAN NOW")]),
              _vm._v(" "),
              _c("a", { on: { click: _vm.scanLater } }, [
                _vm._v("I'LL DO IT LATER"),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }