var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-4 mb-3" }, [
        _c("div", { staticClass: "h4 m-0" }, [
          _vm._v("Identity Verification "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "span",
            {
              staticClass: "badge px-4 py-2 mb-3",
              class: {
                "badge-success": _vm.trulioo.status == "match",
                "badge-warning": _vm.trulioo.status == "nomatch",
                "badge-secondary": _vm.trulioo.status == null,
              },
            },
            [
              _c("div", { staticClass: "h6 mb-0" }, [
                _vm.trulioo.status == "match"
                  ? _c("span", [_vm._v("Verified")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.trulioo.status == "nomatch"
                  ? _c("span", [_vm._v("Not Matching")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.trulioo.status == null
                  ? _c("span", [_vm._v("Not Verified")])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 mb-3" }, [
        _c("div", { staticClass: "h4 m-0" }, [_vm._v("Email Verification")]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "span",
            {
              staticClass: "badge px-4 py-2 mb-3",
              class: {
                "badge-success": _vm.account.confirmed,
                "badge-warning": _vm.account.confirmed == false,
              },
            },
            [
              _c("div", { staticClass: "h6 m-0" }, [
                _vm.account.confirmed == true
                  ? _c("span", [_vm._v("Verified")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.account.confirmed == false
                  ? _c("span", [_vm._v("Not Verified")])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.account.unconfirmed_email
            ? _c("div", [
                _c("p", [
                  _vm._v("Unconfirmed email: "),
                  _c("strong", [_vm._v(_vm._s(_vm.account.unconfirmed_email))]),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 mb-3" }, [
        _c("div", { staticClass: "h4 m-0" }, [_vm._v("Phone Verification")]),
        _vm._v(" "),
        _c("div", { staticClass: "mt-3" }, [
          _c(
            "span",
            {
              staticClass: "badge px-4 py-2 mb-3",
              class: {
                "badge-success": _vm.user.phone_verified,
                "badge-warning": _vm.user.phone_verified == false,
              },
            },
            [
              _c("div", { staticClass: "h6 m-0" }, [
                _vm.user.phone_verified == true
                  ? _c("span", [_vm._v("Verified")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.user.phone_verified == false
                  ? _c("span", [_vm._v("Not Verified")])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.user.phone_verified == false
            ? _c("div", [
                _c("p", [
                  _vm._v("Unverified phone: "),
                  _c("strong", [_vm._v(_vm._s(_vm.user.phone))]),
                ]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "input-group mt-2" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.code,
                        expression: "code",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Verification code" },
                    domProps: { value: _vm.code },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.verify.apply(null, arguments)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.code = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-verification",
                        attrs: { type: "button" },
                        on: { click: _vm.verify },
                      },
                      [_vm._v("Verify")]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("em", { staticClass: "text-black-50" }, [
        _vm._v(
          "Verification is made using your personal data across our partners database to prove you have entered correct data."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("em", { staticClass: "text-black-50" }, [
      _vm._v("We've sent confirmation email. "),
      _c("br"),
      _vm._v(
        "Please check your email and follow the instructions to confirm this email."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("em", { staticClass: "text-black-50" }, [
      _vm._v("We've sent message with code to this number. "),
      _c("br"),
      _vm._v(
        "Please check your messages and enter the recieved code in the input bellow to confirm this phone."
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }