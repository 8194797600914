var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {}, [
      _c("div", { attrs: { id: "dropin-container" } }),
      _vm._v(" "),
      _c("div", { staticClass: "text-center my-3" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary",
            attrs: { id: "store-card", href: "#" },
          },
          [_vm._v("Confirm subscription")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }