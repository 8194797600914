var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modal-login",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-image" }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "close-cta" }, [
            _c(
              "svg",
              {
                staticClass: "close",
                attrs: {
                  "data-dismiss": "modal",
                  height: "25",
                  "stroke-linecap": "round",
                  "stroke-width": "3",
                  stroke: "black",
                  viewBox: "0 0 50 50",
                  width: "25",
                },
              },
              [
                _c("line", { attrs: { x1: "0", x2: "50", y1: "0", y2: "50" } }),
                _vm._v(" "),
                _c("line", { attrs: { x1: "50", x2: "0", y1: "0", y2: "50" } }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("h4", { staticClass: "text-center mb-4" }, [
              _vm._v("Welcome to Trejours"),
            ]),
            _vm._v(" "),
            _vm.status === "complete"
              ? _c(
                  "div",
                  { staticClass: "text-center p-4 login-modal-success" },
                  [_c("h5", [_vm._v("Success!")]), _vm._v(" "), _vm._m(1)]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status === "pending"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "signup-method-wrapper" },
                    [
                      _c("FacebookButton", {
                        attrs: { "with-icon": true, "modal-name": "login" },
                      }),
                      _vm._v(" "),
                      _vm._m(2),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group login-field" }, [
                    _c("label", { attrs: { for: "login-email" } }, [
                      _vm._v("EMAIL"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "email",
                        id: "login-email",
                        placeholder: "",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.login.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group login-field" }, [
                    _c("label", { attrs: { for: "login-password" } }, [
                      _vm._v("PASSWORD"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "password",
                        id: "login-password",
                        placeholder: "",
                      },
                      domProps: { value: _vm.password },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.login.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.password = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "submenu" }, [
                    _c("div", { staticClass: "remember" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rememberMe,
                            expression: "rememberMe",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "scales",
                          name: "scales",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.rememberMe)
                            ? _vm._i(_vm.rememberMe, null) > -1
                            : _vm.rememberMe,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.rememberMe,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.rememberMe = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.rememberMe = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "scales" } }, [
                        _vm._v("Remember Me"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:;" },
                        on: { click: _vm.showPasswordModal },
                      },
                      [_vm._v("Forgot Password?")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.recaptchaSuccessfull,
                          expression: "!recaptchaSuccessfull",
                        },
                      ],
                      staticClass: "recaptcha-wrapper",
                    },
                    [
                      _c("vue-recaptcha", {
                        ref: "recaptcha",
                        attrs: {
                          loadRecaptchaScript: true,
                          sitekey: _vm.sitekey,
                        },
                        on: { verify: _vm.onVerify, expired: _vm.onExpired },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-cta-wrapper" }, [
                    _c(
                      "button",
                      {
                        staticClass: "login-button",
                        attrs: { type: "submit" },
                        on: { click: _vm.login },
                      },
                      [_vm._v("\n              CONTINUE\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "create-acc-button",
                        attrs: { type: "submit" },
                        on: { click: _vm.showRegisterModal },
                      },
                      [
                        _vm._v(
                          "\n              Register an account.\n            "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo text-center" }, [
      _c("img", {
        attrs: { src: require("images/app/login/logo.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n            You have successfully authenticated,\n            "
      ),
      _c("br"),
      _vm._v(
        "\n            the page will be refreshed in a second.\n          "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "btn btn-block google-btn",
        attrs: {
          href: "/accounts/auth/google_oauth2",
          "data-method": "post",
          rel: "nofollow",
        },
      },
      [
        _c("div", { staticClass: "google-ico" }),
        _vm._v("\n              SIGN IN WITH GOOGLE\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }