var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "collapsed", attrs: { id: "checkout-verification" } },
    [
      _vm.isEdit
        ? _c("div", [
            _c("div", [
              _c("p", { attrs: { id: "disclaimer" } }, [
                _vm._v(
                  "\n\t\t\t\t\tFor safety and security reasons, we need to collect information\n\t\t\t\t\tconfirming your identity. For more info, please check out our Privacy\n\t\t\t\t\tPolicy.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              !_vm.user.phone_verified
                ? _c("div", { attrs: { id: "mobile-phone" } }, [
                    _c("div", { staticClass: "header" }, [
                      _vm._v("\n\t\t\t\t\t\tMobile Phone\n\t\t\t\t\t"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n\t\t\t\t\t\tMOBILE PHONE VERIFICATION\n\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { id: "send-sms" },
                        on: { click: _vm.verifyPhoneRequest },
                      },
                      [_vm._v(_vm._s(_vm.sendText))]
                    ),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "sms-code" } }, [
                      _vm._v(
                        "\n\t\t\t\t\t\tTYPE VERIFICATION CODE HERE\n\t\t\t\t\t"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "sms-code-wrapper" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.code,
                            expression: "code",
                          },
                        ],
                        attrs: {
                          type: "text",
                          name: "sms-code",
                          id: "sms-code",
                        },
                        domProps: { value: _vm.code },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.code = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.isSent
                        ? _c("div", { attrs: { id: "sms-code-sent" } }, [
                            _vm._v(
                              "* We have sent a code to your phone. Please check your messages for this code."
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { attrs: { id: "date-of-birth" } }, [
                _c("div", { staticClass: "header" }, [
                  _vm._v("\n\t\t\t\t\t\tDate of Birth\n\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-item sms-item",
                    class: _vm.birthDateErrorHandler,
                  },
                  [
                    _c(
                      "label",
                      { staticClass: "sms-label", attrs: { for: "birth" } },
                      [_vm._v("DATE OF BIRTH")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.birthday,
                          expression: "birthday",
                        },
                      ],
                      attrs: { type: "date", name: "birth", id: "birth" },
                      domProps: { value: _vm.birthday },
                      on: {
                        click: function ($event) {
                          _vm.birthDateErrorHandler = ""
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.birthday = $event.target.value
                        },
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            !this.returningCustomer
              ? _c(
                  "div",
                  { attrs: { id: "persona-verification" } },
                  [
                    _c("div", { staticClass: "header" }, [
                      _vm._v(
                        "\n          Drivers License / State ID Scan\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("Persona", {
                      attrs: {
                        modalVisible: _vm.personaModalVisible,
                        personaEnvironment: _vm.personaEnvironment,
                        personaTemplateId: _vm.personaTemplateId,
                        personaEnvironmentId: _vm.personaEnvironmentId,
                      },
                      on: {
                        verificationCompleted: _vm.onVerificationCompleted,
                        scanNow: function ($event) {
                          _vm.personaModalVisible = false
                        },
                        scanLater: _vm.scanLaterClicked,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.verified
              ? _c("div", { staticClass: "verified" }, [
                  _c("p", [_vm._v("User verified.")]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "save-container" }, [
              _c("button", { on: { click: _vm.saveBtnHandler } }, [
                _vm._v("SAVE AND CONTINUE"),
              ]),
            ]),
          ])
        : _c("div", { staticClass: "form-details" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { id: "veri-edit" },
                on: {
                  click: function ($event) {
                    _vm.isEdit = true
                  },
                },
              },
              [_vm._v("EDIT")]
            ),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "verification-pending" }, [
      _c("h5", { staticClass: "pending" }, [_vm._v("Verification Pending")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("(we will contact you should we need more information)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }