var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "show-modal-review",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("dl", [
              _c("dt", [_vm._v("Product condition upon arrival:")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.vendorReview.condition_text))]),
              _vm._v(" "),
              _c("dt", [_vm._v("Was the package damaged?")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.vendorReview.package_damaged_text))]),
              _vm._v(" "),
              _c("dt", [
                _vm._v("Anything you would like to add about this product:"),
              ]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.vendorReview.product_comment))]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v(" Your review")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("close x")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }