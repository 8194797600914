var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "checkout-shipping" } }, [
    _vm.isEdit
      ? _c("div", { staticClass: "editable-form" }, [
          _c("p", [_vm._v("Shipping Details")]),
          _vm._v(" "),
          _c("div", { staticClass: "name" }, [
            _c(
              "div",
              {
                staticClass: "form-item mr-input",
                class: _vm.firstNameErrorHandler,
                attrs: { id: "first-inp" },
              },
              [
                _c("label", { attrs: { for: "first-name" } }, [
                  _vm._v("FIRST NAME"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.firstName,
                      expression: "firstName",
                    },
                  ],
                  attrs: { type: "text", name: "first-name", id: "first-name" },
                  domProps: { value: _vm.firstName },
                  on: {
                    keydown: function ($event) {
                      _vm.firstNameErrorHandler = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.firstName = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-item", class: _vm.lastNameErrorHandler },
              [
                _c("label", { attrs: { for: "last-name" } }, [
                  _vm._v("LAST NAME"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.lastName,
                      expression: "lastName",
                    },
                  ],
                  attrs: { type: "text", name: "last-name", id: "last-name" },
                  domProps: { value: _vm.lastName },
                  on: {
                    keydown: function ($event) {
                      _vm.lastNameErrorHandler = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.lastName = $event.target.value
                    },
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-item", class: _vm.addressStreetErrorHandler },
            [
              _c("label", { attrs: { for: "address" } }, [_vm._v("ADDRESS")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.addressStreet,
                    expression: "addressStreet",
                  },
                ],
                attrs: { type: "text", name: "address", id: "address" },
                domProps: { value: _vm.addressStreet },
                on: {
                  keydown: function ($event) {
                    _vm.addressStreetErrorHandler = ""
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.addressStreet = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "form-item" }, [
            _c("label", { attrs: { for: "street_number" } }, [
              _vm._v("APT/UNIT/SUITE(optional)"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.addressNumber,
                  expression: "addressNumber",
                },
              ],
              attrs: {
                type: "text",
                name: "street_number",
                id: "street_number",
              },
              domProps: { value: _vm.addressNumber },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.addressNumber = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "rest-address-info" }, [
            _c(
              "div",
              {
                staticClass: "form-item mr-input",
                class: _vm.addressCityErrorHandler,
                attrs: { id: "city-box" },
              },
              [
                _c("label", { attrs: { for: "city" } }, [_vm._v("CITY")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addressCity,
                      expression: "addressCity",
                    },
                  ],
                  attrs: { type: "text", name: "city", id: "city" },
                  domProps: { value: _vm.addressCity },
                  on: {
                    keydown: function ($event) {
                      _vm.addressCityErrorHandler = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.addressCity = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-item mr-input", attrs: { id: "state-box" } },
              [
                _c("label", { attrs: { for: "state" } }, [_vm._v("STATE")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.addressState,
                        expression: "addressState",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.addressState = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.states, function (state) {
                    return _c(
                      "option",
                      { key: state[1], domProps: { value: state[1] } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(state[0]) +
                            "\n\t\t\t\t\t\t"
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-item mr-input",
                class: _vm.addressZipErrorHandler,
                attrs: { id: "zipcode-box" },
              },
              [
                _c("label", { attrs: { for: "zipcode" } }, [_vm._v("ZIPCODE")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.addressZip,
                      expression: "addressZip",
                    },
                  ],
                  attrs: { type: "text", name: "zipcode", id: "zipcode" },
                  domProps: { value: _vm.addressZip },
                  on: {
                    keydown: function ($event) {
                      _vm.addressZipErrorHandler = ""
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.addressZip = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-item mr-input",
                class: _vm.mobileErrorHandler,
                attrs: { id: "number-box" },
              },
              [
                _c("label", { attrs: { for: "phone" } }, [
                  _vm._v("MOBILE NUMBER"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "phone-prefix" }, [
                    _vm._v("\n              +1\n            "),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.phone,
                        expression: "phone",
                      },
                    ],
                    attrs: { type: "text", name: "phone", id: "phone" },
                    domProps: { value: _vm.phone },
                    on: {
                      keydown: function ($event) {
                        _vm.mobileErrorHandler = ""
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.phone = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "save-container" }, [
            _c("button", { on: { click: _vm.saveBtnHandler } }, [
              _vm._v("SAVE AND CONTINUE"),
            ]),
          ]),
        ])
      : _c("div", { staticClass: "form-details" }, [
          _c("div", { staticClass: "shipping-address" }, [
            _c("h5", [_vm._v("Shipping Address")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName)),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.addressStreet))]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.addressNumber))]),
            _vm._v(" "),
            _c("p", { staticClass: "city" }, [
              _vm._v(
                "\n\t\t\t\t\t" +
                  _vm._s(_vm.addressCity) +
                  ", " +
                  _vm._s(_vm.addressState) +
                  "\n\t\t\t\t\t" +
                  _vm._s(_vm.addressZip) +
                  "\n\t\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(this.phone))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "email-dates" }, [
            _c("div", { staticClass: "email" }, [
              _c("h5", [_vm._v("Email")]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.user.email))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "dates" }, [
              _c("h5", [_vm._v("Delivery / Return Dates")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Delivery Date " + _vm._s(this.rent_at) + " - FREE"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Return Date " + _vm._s(this.return_at) + " - FREE"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  _vm.isEdit = true
                },
              },
            },
            [_vm._v("EDIT")]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n\t\t\t\t\t  This number will be used for verification\n\t\t\t\t\t  "
      ),
      _c("br"),
      _vm._v(
        "\n\t\t\t\t\t  Please enter your phone number with no spaces: 4132135672\n          "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }