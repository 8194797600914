var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        {
          attrs: { name: "slide-fade" },
          on: { "after-leave": _vm.afterLeave },
        },
        [
          _vm.show
            ? _c("div", [
                _c("hr"),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3 text-center h6" }, [
                  _vm.isNewAddress
                    ? _c("span", [_vm._v("Add new address")])
                    : _c("span", [_vm._v("Edit address")]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-8" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.id_for("street") } }, [
                        _vm._v("Street & Number"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address.street,
                            expression: "address.street",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.address.street },
                        attrs: {
                          type: "text",
                          id: _vm.id_for("street"),
                          placeholder: "Street & Number",
                        },
                        domProps: { value: _vm.address.street },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.save.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.address, "street", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.address.street
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              _vm._s(_vm.errors.address.street.join(", "))
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.id_for("number") } }, [
                        _vm._v("Apt. Number"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address.number,
                            expression: "address.number",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.address.number },
                        attrs: {
                          type: "text",
                          id: _vm.id_for("number"),
                          placeholder: "Apt. Number",
                        },
                        domProps: { value: _vm.address.number },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.save.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.address, "number", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.address.number
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              _vm._s(_vm.errors.address.number.join(", "))
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.id_for("city") } }, [
                        _vm._v("City"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address.city,
                            expression: "address.city",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.address.city },
                        attrs: {
                          type: "text",
                          id: _vm.id_for("city"),
                          placeholder: "City",
                        },
                        domProps: { value: _vm.address.city },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.save.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.address, "city", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.address.city
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.errors.address.city.join(", "))),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: _vm.id_for("state") } }, [
                          _vm._v("State"),
                        ]),
                        _vm._v(" "),
                        _c("b-form-select", {
                          staticClass: "mb-3",
                          class: { "is-invalid": _vm.errors.address.state },
                          attrs: {
                            id: "state",
                            options: _vm.states,
                            "value-field": "abbreviation",
                            "text-field": "name",
                          },
                          model: {
                            value: _vm.address.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.address, "state", $$v)
                            },
                            expression: "address.state",
                          },
                        }),
                        _vm._v(" "),
                        _vm.errors.address.state
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                _vm._s(_vm.errors.address.state.join(", "))
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-6 col-sm-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: _vm.id_for("zip") } }, [
                        _vm._v("Zip Code"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.address.zip,
                            expression: "address.zip",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.address.zip },
                        attrs: {
                          type: "text",
                          id: _vm.id_for("zip"),
                          placeholder: "Zip Code",
                        },
                        domProps: { value: _vm.address.zip },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.save.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.address, "zip", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.address.zip
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(_vm.errors.address.zip.join(", "))),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.save },
                  },
                  [_vm._v("Save address")]
                ),
                _vm._v(" "),
                _vm.canRemove
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-secondary",
                        attrs: { type: "button" },
                        on: { click: _vm.remove },
                      },
                      [_vm._v("Remove address")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr", { staticClass: "mb-5" }),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }