import * as Toastr from 'toastr';
import axios from 'axios';

import CategoryAutocomplete from "../components/categories/autocomplete/main"
import CategoryItem from "../components/categories/item"
import CollectionAutocomplete from "../components/collections/autocomplete/main"
import CollectionItem from "../components/collections/item"
import OccasionAutocomplete from "../components/occasions/autocomplete/main"
import OccasionItem from "../components/occasions/item"
import ProductItem from "../components/products/item";

import draggable from 'vuedraggable';
import JsonApi from "../../vendor/json-api"
import Vue from "vue/dist/vue.esm"

export default class AdminProducts {
  addSubscriptionProducts() {
    let productIds = [];

    $(".border-yellow").each(function(i, item) {
      productIds.push(item.id.split('-')[1]);
    });

    $(".product-thumb").each(function(i, item) {
      $(item).on('click', function() {
        let id = this.id.split('-')[1];
        if ($(this).hasClass('border-yellow')) {
          productIds.splice( productIds.indexOf(id), 1 );
          $(this).removeClass('border-yellow');
        } else {
          productIds.push(id);
          $(this).addClass('border-yellow');
        }
      });
    });

    $('#saveSubscriptionProducts').on('click', function() {
      $.ajax({
        method: 'POST',
        url: '/admin/products/save_subscription_products',
        data: {
          product_ids: productIds
        },
        success: function(res) {
          Toastr.success('Successfully added to subscription product');
        },
        error: function(res) {
          Toastr.error('Error saving subscription product.');
        }
      });
    });
  }

  setPosition() {
    var page = 1;
    $(function() {
      $("a#backToTop").click(function () {
        $("body,html").animate({ scrollTop: 0 }, 600);
        return false;
      });
    });

    new Vue({
      el: '#products-app',
      components: { ProductItem, draggable },
      data: {
        products: gon.payload.data,
      },
      methods: {
        onEnd: function(event) {
          var positions = $('#preference_value').val().split(',');
          $.each(this.products, function(index, value) {
            positions = move_array(positions, positions.indexOf(value.id), index);
          });

          $('#preference_value').val(positions.join(','));

          function move_array(arr, old_index, new_index) {
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            return arr;
          };
        },
        loadMore: function(event) {
          event.preventDefault();
          page = page + 1;
          axios.get(`/admin/products/set_position.json?page=${page}`).then(response => {
            this.products = this.products.concat(response.data.data);
          });
        }
      }
    });

    $('#products_table').append($('#products_body'));
  }
  show(){
this.edit();
  }

  edit() {
    console.log('bob');
    var categoriesAppSelector = '#product-categories-app';
    if ($(categoriesAppSelector).length > 0) {
      var app = new Vue({
        el: categoriesAppSelector,
        components: { CategoryItem, CategoryAutocomplete },
        data: {
          items: JsonApi.deserialize(gon.payload.categorizations).data
        }
      });
    }

    var occasionsAppSelector = '#product-occasions-app';
    if ($(occasionsAppSelector).length > 0) {
      new Vue({
        el: occasionsAppSelector,
        components: { OccasionItem, OccasionAutocomplete },
        data: {
          items: JsonApi.deserialize(gon.payload.occasions).data
        }
      });
    }

    var collectionAppSelector = '#product-collections-app';
    if ($(collectionAppSelector).length > 0) {
      new Vue({
        el: collectionAppSelector,
        components: { CollectionItem, CollectionAutocomplete },
        data: {
          items: JsonApi.deserialize(gon.payload.collections).data
        }
      });
    }
  }
}
