<template lang="html">
<div>
  <div class="container">
    <div class="hero">
      <button id="hiw-hero-player-button" @click="showModal">
        <i class="icon far fa-play-circle"></i>
      </button>
    </div>
    <div class="stripes">
        <div class="stripe-first"></div>
      <div class="stripe-second"></div>
    </div>
    <div class="description">
      <div class="desc">
      <p class="title">A SHORTCUT TO LUXURY</p>
      <div class="pdesc">
      <p>Renting beautiful jewelry has <span class="pdesc__second-line--mobile">never been <span class="pdesc__second-line--desktop">this easy - or this fun.</span></span></p>
      </div>
      </div>
      <button id="hiw-player-button" @click="showModal"><span>WATCH THE VIDEO TO FIND OUT</span> <i class="icon far fa-play-circle"></i></button>
    </div>
  </div>
  <HowItWorksVideoModal v-show="isModalVisible" @close="closeModal" ></HowItWorksVideoModal>
</div>
</template>

<script>
import HowItWorksVideoModal from './how_it_works_video.vue'

export default {
  components: {
    HowItWorksVideoModal: HowItWorksVideoModal
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal(){
      this.isModalVisible = true;
      $('#hiw-video').get(0).play();
    },
    closeModal(){
      this.isModalVisible = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  #hiw-player-button {
    .icon {
      margin-left: 5px;
    }
  }

  #hiw-hero-player-button {
    position: absolute;
    left: calc(50% - 15px);
    top: 200px;
    border: 0;
    background: transparent;
    color: white;
    transform: scale(5);
    z-index: 1;
    filter: drop-shadow(-1px 1px 1px rgba(0, 0, 0, 0.6));

    @media(min-width: 835px) {
      left: 647px;
      top: 232px;
    }

    @media(min-width: 1455px) {
      left: 44.5vw;
      top: 16vw;
    }
  }
</style>
