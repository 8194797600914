var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "step-header" }, [
      _vm._v(
        "\n    where do we ship the jewelry? (free shipping both ways)\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-8 col-md-7" },
        [
          _c("h3", { staticClass: "fieldset-label" }, [
            _vm._v("Add Shipping Information"),
          ]),
          _vm._v(" "),
          _vm.edit
            ? _c("address-form", {
                attrs: { address: _vm.address },
                on: { "set-address": _vm.setAddress },
              })
            : _c("div", [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.address.street) +
                      ", " +
                      _vm._s(_vm.address.number) +
                      "\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.address.city) +
                      ", " +
                      _vm._s(_vm.address.state) +
                      ", " +
                      _vm._s(_vm.address.zip)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "edit-btn",
                    attrs: { href: "javascript:;" },
                    on: {
                      click: function ($event) {
                        _vm.edit = true
                      },
                    },
                  },
                  [_vm._v("Edit")]
                ),
              ]),
          _vm._v(" "),
          _c("h3", { staticClass: "fieldset-label" }, [_vm._v("Review order")]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "button-wrapper" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.nextStep },
              },
              [_vm._v("Place Order")]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-4 col-md-5" }, [
      _c("img", {
        attrs: { src: require("images/app/subscriptions/step-3.webp") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("table", [
      _c("tr", [
        _c("td", [_vm._v("Subtotal")]),
        _vm._v(" "),
        _c("td", [_vm._v("Free first month")]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Insurance")]),
        _vm._v(" "),
        _c("td", [_vm._v("Free")]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Shipping & handling")]),
        _vm._v(" "),
        _c("td", [_vm._v("Free")]),
      ]),
      _vm._v(" "),
      _c("tr", [
        _c("td", [_vm._v("Return via UPS")]),
        _vm._v(" "),
        _c("td", [_vm._v("Free")]),
      ]),
      _vm._v(" "),
      _c("tr", { staticClass: "total" }, [
        _c("td", [_vm._v("Order Total")]),
        _vm._v(" "),
        _c("td", [_vm._v("Free")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }