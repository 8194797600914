var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "account-first-name" } }, [
            _vm._v("First name"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.account.first_name,
                expression: "account.first_name",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.account.first_name },
            attrs: {
              type: "text",
              id: "account-first-name",
              placeholder: "First name",
            },
            domProps: { value: _vm.account.first_name },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.account, "first_name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.account.first_name
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.account.first_name.join(", "))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "account-last-name" } }, [
            _vm._v("Last name"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.account.last_name,
                expression: "account.last_name",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.account.last_name },
            attrs: {
              type: "text",
              id: "account-last-name",
              placeholder: "Last name",
            },
            domProps: { value: _vm.account.last_name },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.account, "last_name", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.account.last_name
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.account.last_name.join(", "))),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "vendor-phone" } }, [_vm._v("Phone")]),
          _vm._v(" "),
          _c("div", [
            _c("span", { staticClass: "phone-prefix" }, [
              _vm._v("\n            +1\n          "),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.vendor.phone,
                  expression: "vendor.phone",
                },
              ],
              staticClass: "form-control",
              class: { "is-invalid": _vm.errors.vendor.phone },
              attrs: { type: "text", id: "vendor-phone", placeholder: "Phone" },
              domProps: { value: _vm.vendor.phone },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.update.apply(null, arguments)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.vendor, "phone", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("small", { staticClass: "text-black-50 fs" }, [
            _vm._v(
              "Please enter your phone number with no spaces: 4132135672\n        "
            ),
          ]),
          _vm._v(" "),
          _vm.errors.vendor.phone
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.vendor.phone.join(", "))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "account-email" } }, [
            _vm._v("Email address"),
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.account.email,
                expression: "account.email",
              },
            ],
            staticClass: "form-control",
            class: { "is-invalid": _vm.errors.account.email },
            attrs: {
              type: "email",
              id: "account-email",
              placeholder: "Your email address",
            },
            domProps: { value: _vm.account.email },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.update.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.account, "email", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.errors.account.email
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(_vm._s(_vm.errors.account.email.join(", "))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("small", { staticClass: "text-black-50 fs" }, [
            _vm._v(
              "You will recieve verification email on the new email address, before the changes occur."
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { type: "button" },
        on: { click: _vm.update },
      },
      [_vm._v("Save changes")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }