var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "list-group-item list-group-item-action",
      attrs: { href: "javascript:;" },
      on: { click: _vm.setItem },
    },
    [
      !_vm.item.persisted
        ? _c("span", { staticClass: "mr-1" }, [_vm._v("Create category ")])
        : _vm._e(),
      _vm._v("\n  " + _vm._s(_vm.item.tree_name) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }