import { render, staticRenderFns } from "./how_it_works_video.vue?vue&type=template&id=2a2d08fe&lang=html"
import script from "./how_it_works_video.vue?vue&type=script&lang=js"
export * from "./how_it_works_video.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a2d08fe')) {
      api.createRecord('2a2d08fe', component.options)
    } else {
      api.reload('2a2d08fe', component.options)
    }
    module.hot.accept("./how_it_works_video.vue?vue&type=template&id=2a2d08fe&lang=html", function () {
      api.rerender('2a2d08fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/client/javascripts/app/components/how_it_works/how_it_works_video.vue"
export default component.exports