var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "step-header" }, [
      _vm._v("\n    all set. confirm your subscription trial.\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-8 col-md-7" },
        [
          _c("h3", { staticClass: "fieldset-label" }, [
            _vm._v("Add Billing Information"),
          ]),
          _vm._v(" "),
          !_vm.account.use_shipping_as_billing_address && _vm.edit
            ? _c("address-form", {
                attrs: { address: _vm.address },
                on: { "set-address": _vm.setAddress },
              })
            : _c("div", [
                _c("p", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.addressShipping.street) +
                      ", " +
                      _vm._s(_vm.addressShipping.number) +
                      "\n          "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.addressShipping.city) +
                      ", " +
                      _vm._s(_vm.addressShipping.state) +
                      ", " +
                      _vm._s(_vm.addressShipping.zip)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "edit-btn",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.enterEdit },
                  },
                  [_vm._v("Edit")]
                ),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-check" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.account.use_shipping_as_billing_address,
                  expression: "account.use_shipping_as_billing_address",
                },
              ],
              staticClass: "form-check-input",
              attrs: {
                type: "checkbox",
                id: "use_shipping_as_billing_address",
              },
              domProps: {
                checked: Array.isArray(
                  _vm.account.use_shipping_as_billing_address
                )
                  ? _vm._i(_vm.account.use_shipping_as_billing_address, null) >
                    -1
                  : _vm.account.use_shipping_as_billing_address,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.account.use_shipping_as_billing_address,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(
                          _vm.account,
                          "use_shipping_as_billing_address",
                          $$a.concat([$$v])
                        )
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.account,
                          "use_shipping_as_billing_address",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(
                      _vm.account,
                      "use_shipping_as_billing_address",
                      $$c
                    )
                  }
                },
              },
            }),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass: "form-check-label",
                attrs: { for: "use_shipping_as_billing_address" },
              },
              [_vm._v("Billing address is same as shipping address")]
            ),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "fieldset-label" }, [
            _vm._v("Add Payment Information"),
          ]),
          _vm._v(" "),
          _c("subscription-form", { attrs: { account: _vm.account } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-4 col-md-5" }, [
      _c("img", {
        attrs: { src: require("images/app/subscriptions/step-4.webp") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }