var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-wrapper" }, [
    _c("div", { staticClass: "order-header" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm._v("\n        Order Number:\n        "),
          _c("a", { attrs: { href: _vm.order.links.self } }, [
            _vm._v(_vm._s(_vm.order.id_formatted)),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n          " + _vm._s(_vm.createdAt) + "\n        "),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 text-right" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-outline-primary btn-sm",
              attrs: { href: _vm.order.links.self },
            },
            [_vm._v("Order Details")]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order-body" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "address" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Shipping Address")]),
                _vm._v(" "),
                _c("div", { staticClass: "sub text-black-50" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.order.shipping_formatted) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "address" }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("strong", [_vm._v("Billing Address")]),
                _vm._v(" "),
                _c("div", { staticClass: "sub text-black-50" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.order.billing_formatted) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Expected date to arrive")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.order.rent_at) + "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Date to be sent back")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.return_at) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Rental price")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.rental_price_formatted) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Market price")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.market_price_formatted) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-4 text-center mb-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Status")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50 text-uppercase" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.status_humanized) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("button", { staticClass: "btn btn-primary" }, [
              _vm._v("View Invoice"),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "mt-0" }),
        _vm._v(" "),
        _vm._l(_vm.order.order_items.data, function (order_item) {
          return _c("order-item", {
            key: order_item.id,
            attrs: { order_item: order_item },
            on: {
              "review-modal": _vm.reviewModal,
              "show-review-modal": _vm.showReviewModal,
              "order-cancelled": _vm.orderCancelled,
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }