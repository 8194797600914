var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        {
          attrs: { name: "slide-fade" },
          on: { "after-leave": _vm.afterLeave },
        },
        [
          _vm.show
            ? _c(
                "div",
                { staticClass: "mb-30" },
                [
                  _c("h4", [
                    _c("span", { staticClass: "badge badge-dark p-2" }, [
                      _vm._v(
                        "\n          Unit #" +
                          _vm._s(_vm.item.id) +
                          " - " +
                          _vm._s(_vm.item.variant.data.name) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.item.availableStatuses, function (value, label) {
                    return _c(
                      "span",
                      {
                        staticClass: "d-inline-block radio-custom",
                        class: {
                          "radio-success": label == "available",
                          "radio-danger":
                            ["maintenance", "stopped"].indexOf(label) > -1,
                        },
                      },
                      [
                        _vm._v(">\n        "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.status,
                              expression: "item.status",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "unit_" + _vm.item.id + "_status_" + value,
                            name: "unit[" + _vm.item.id + "][status]",
                          },
                          domProps: {
                            value: label,
                            checked: _vm._q(_vm.item.status, label),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.item, "status", label)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "unit_" + _vm.item.id + "_status_" + value,
                            },
                          },
                          [_vm._v(_vm._s(label))]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group string optional" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-control-label string optional",
                        attrs: { for: _vm.sku_id },
                      },
                      [_vm._v("Stock Keeping Unit")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.sku,
                          expression: "item.sku",
                        },
                      ],
                      staticClass: "form-control string optional",
                      class: { "is-invalid": _vm.errors.sku },
                      attrs: { type: "text", id: _vm.sku_id },
                      domProps: { value: _vm.item.sku },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.saveItem.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.item, "sku", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        "Vendor SKU used for personal indetification of the unit"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.errors.sku
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.errors.sku.join(", "))),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group text optional" }, [
                    _c(
                      "label",
                      {
                        staticClass: "form-control-label text optional",
                        attrs: { for: _vm.notes_id },
                      },
                      [_vm._v("Additional notes")]
                    ),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.item.notes,
                          expression: "item.notes",
                        },
                      ],
                      staticClass: "form-control text optional",
                      class: { "is-invalid": _vm.errors.notes },
                      attrs: { rows: "3", id: _vm.notes_id },
                      domProps: { value: _vm.item.notes },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.item, "notes", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        "Additional information related to the unit. Will be displayed on the vendor on order fulfillment."
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.errors.notes
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          _vm._v(_vm._s(_vm.errors.notes.join(", "))),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-2" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-outline btn-outline-secondary btn-default mt-auto mr-1",
                        attrs: { type: "button" },
                        on: { click: _vm.saveItem },
                      },
                      [_vm._v("Save")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline btn-danger mt-auto",
                        attrs: { type: "button" },
                        on: { click: _vm.removeItem },
                      },
                      [_vm._v("Remove")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-outline btn-outline-secondary btn-default",
                        attrs: { href: _vm.item.links.absences },
                      },
                      [_vm._v("Availability")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }