var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "newsletter-container" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.email,
          expression: "email",
        },
      ],
      attrs: {
        type: "email",
        name: "email",
        id: "newsletter-email",
        placeholder: "YOUR EMAIL ADDRESS HERE",
      },
      domProps: { value: _vm.email },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.subscribeEmail.apply(null, arguments)
        },
        input: function ($event) {
          if ($event.target.composing) return
          _vm.email = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c("button", { on: { click: _vm.subscribeEmail } }, [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading,
            expression: "!loading",
          },
        ],
        attrs: { src: require("../../../../images/app/right-arrow.webp") },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [_vm._v("Loading...")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }