var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "18",
        viewBox: "0 0 20 18",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        style: this.isFav ? { fill: _vm.changeColor } : `fill:white`,
        attrs: {
          d: "m14.30371,1.00274c-0.4,0 -0.9,0.1 -1.3,0.2c-0.4,0.1 -0.8,0.3 -1.2,0.6c-0.4,0.3 -0.7,0.5 -1,0.7c-0.3,0.2 -0.5,0.5 -0.8,0.7c-0.2,-0.2 -0.5,-0.5 -0.8,-0.7c-0.3,-0.2 -0.6,-0.5 -1,-0.7c-0.4,-0.3 -0.8,-0.5 -1.2,-0.6c-0.4,-0.1 -0.8,-0.2 -1.3,-0.2c-1.3,-0.1 -2.6,0.4 -3.5,1.3c-0.9,1 -1.4,2.3 -1.3,3.6c0,0.5 0.1,1 0.2,1.4c0.1,0.4 0.3,0.9 0.5,1.3c0.2,0.4 0.4,0.7 0.7,1c0.3,0.3 0.4,0.6 0.6,0.7c0.1,0.1 0.2,0.2 0.3,0.3l6.3,6.3c0.2,0.2 0.6,0.3 0.9,0c0,0 0,0 0,0l6.3,-6.3c1.4,-1.2 2.2,-2.9 2.3,-4.7c0.1,-1.3 -0.4,-2.6 -1.3,-3.6c-0.9,-0.9 -2.1,-1.3 -3.4,-1.3z",
          id: "heart-svg",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }