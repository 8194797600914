var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-wrapper" }, [
    _c("div", { staticClass: "order-header" }, [
      _c("div", { staticClass: "row d-flex align-items-center" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm._v("\n        Order Number:\n        "),
          _c("a", { attrs: { href: _vm.order.links.self } }, [
            _vm._v(_vm._s(_vm.order.id_formatted)),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("span", [
            _vm._v("\n            " + _vm._s(_vm.createdAt) + "\n          "),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6 text-right" },
          [
            !_vm.orderExpired
              ? _c("countdown", {
                  attrs: { time: _vm.timeLeft, interval: 100 },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "badge badge-light px-3 py-2 text-center",
                              },
                              [
                                _c(
                                  "strong",
                                  { staticClass: "h6 mb-1 d-block" },
                                  [_vm._v(_vm._s(props.days) + " DAYS")]
                                ),
                                _vm._v(" "),
                                _c("div", {}, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(props.hours) +
                                      ":" +
                                      _vm._s(props.minutes) +
                                      ":" +
                                      _vm._s(props.seconds) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    353838670
                  ),
                })
              : _c("div", [
                  _c("span", { staticClass: "badge badge-danger px-3 py-2" }, [
                    _vm._v("Expired"),
                  ]),
                ]),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "order-body" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("User Delivery Address")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.shipping_formatted) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("User Full Name")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.shipping.first_name) +
                    "\n            " +
                    _vm._s(_vm.order.shipping.last_name) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("User Phone Number")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.shipping.phone) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("User Email")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.shipping.email) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Ship By Date")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.ship_by_date) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Return By Date")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.return_at) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-3 text-center" }, [
            _c("div", { staticClass: "mb-3" }, [
              _c("strong", [_vm._v("Status")]),
              _vm._v(" "),
              _c("div", { staticClass: "sub text-black-50 text-uppercase" }, [
                _vm._v(_vm._s(_vm.sub_order.status)),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.showLabels ? _c("hr") : _vm._e(),
        _vm._v(" "),
        _vm.showLabels
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _vm.sub_order.vendor_shipment.shipment_id
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            href: _vm.sub_order.vendor_shipment.label_url,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Outgoing Shipment Label")]
                      )
                    : !_vm.orderReturned
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary",
                          on: { click: _vm.sendSubOrder },
                        },
                        [_vm._v(_vm._s(_vm.shipment_generate_button))]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _vm.sub_order.user_shipment.shipment_id
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            href: _vm.sub_order.user_shipment.label_url,
                            target: "_blank",
                          },
                        },
                        [_vm._v("Return Shipment Label")]
                      )
                    : !_vm.orderReturned
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-primary",
                          on: { click: _vm.sendSubOrder },
                        },
                        [_vm._v(_vm._s(_vm.return_generate_button))]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("strong", [_vm._v("Outgoing Shipment Tracking ID:")]),
                  _vm._v(" "),
                  _vm.sub_order.vendor_shipment.tracking_code
                    ? _c(
                        "a",
                        {
                          staticClass: "track-link",
                          attrs: {
                            href: _vm.sub_order.vendor_shipment.tracking_url,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.sub_order.vendor_shipment.tracking_code
                            ) + "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-3" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("strong", [_vm._v("Return Shipment Tracking ID:")]),
                  _vm._v(" "),
                  _vm.sub_order.user_shipment.tracking_code
                    ? _c(
                        "a",
                        {
                          staticClass: "track-link",
                          attrs: {
                            href: _vm.sub_order.user_shipment.tracking_url,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.sub_order.user_shipment.tracking_code) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isForm
          ? _c("div", { staticClass: "border rounded row fedex-section" }, [
              _vm.isForm
                ? _c(
                    "div",
                    {
                      staticClass: "col",
                      attrs: { id: "outgoing-service-options" },
                    },
                    [
                      _c("div", { staticClass: "form-item" }, [
                        _vm.changedDefaultOutgoingService
                          ? _c("p", { staticClass: "price-warning" }, [
                              _vm._v("This increases the shipment price"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-select" }, [
                          _c(
                            "label",
                            { attrs: { for: "outgoing-service-options" } },
                            [_vm._v("Choose Outgoing FedEx Service Option")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shipping_options.outgoing_service,
                                  expression:
                                    "shipping_options.outgoing_service",
                                },
                              ],
                              staticClass: "mb-3 custom-select",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.shipping_options,
                                    "outgoing_service",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.service_options,
                              function (service_option) {
                                return _c(
                                  "option",
                                  {
                                    key: service_option[1],
                                    domProps: { value: service_option[1] },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(service_option[0]) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "form-item",
                          staticStyle: { "padding-top": "11px" },
                        },
                        [
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shipping_options.outgoing_hold_at,
                                  expression:
                                    "shipping_options.outgoing_hold_at",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "checkbox",
                                id: "outgoing-hold-at",
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.shipping_options.outgoing_hold_at
                                )
                                  ? _vm._i(
                                      _vm.shipping_options.outgoing_hold_at,
                                      null
                                    ) > -1
                                  : _vm.shipping_options.outgoing_hold_at,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.shipping_options.outgoing_hold_at,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.shipping_options,
                                          "outgoing_hold_at",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.shipping_options,
                                          "outgoing_hold_at",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.shipping_options,
                                      "outgoing_hold_at",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "outgoing-hold-at" },
                              },
                              [
                                _vm._v(
                                  "Outgoing shipment to nearest FedEx point"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isForm
                ? _c(
                    "div",
                    {
                      staticClass: "col",
                      attrs: { id: "return-service-options" },
                    },
                    [
                      _c("div", { staticClass: "form-item" }, [
                        _vm.changedDefaultReturnService
                          ? _c("p", { staticClass: "price-warning" }, [
                              _vm._v("This increases the shipment price"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-select" }, [
                          _c(
                            "label",
                            { attrs: { for: "return-service-options" } },
                            [_vm._v("Choose Return FedEx Service Option")]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shipping_options.return_service,
                                  expression: "shipping_options.return_service",
                                },
                              ],
                              staticClass: "mb-3 custom-select",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.shipping_options,
                                    "return_service",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.service_options,
                              function (service_option) {
                                return _c(
                                  "option",
                                  {
                                    key: service_option[1],
                                    domProps: { value: service_option[1] },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(service_option[0]) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "form-item",
                          staticStyle: { "padding-top": "11px" },
                        },
                        [
                          _c("div", { staticClass: "form-check" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.shipping_options.return_hold_at,
                                  expression: "shipping_options.return_hold_at",
                                },
                              ],
                              staticClass: "form-check-input",
                              attrs: { type: "checkbox", id: "return-hold-at" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.shipping_options.return_hold_at
                                )
                                  ? _vm._i(
                                      _vm.shipping_options.return_hold_at,
                                      null
                                    ) > -1
                                  : _vm.shipping_options.return_hold_at,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.shipping_options.return_hold_at,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.shipping_options,
                                          "return_hold_at",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.shipping_options,
                                          "return_hold_at",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.shipping_options,
                                      "return_hold_at",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "return-hold-at" },
                              },
                              [_vm._v("Return shipment to nearest FedEx point")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.changedDefaultReturnHoldAt
                            ? _c("p", { staticClass: "price-warning" }, [
                                _vm._v("This increases the shipment price"),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isForm
                ? _c("div", { staticClass: "col align-self-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary send-order",
                        attrs: { disabled: _vm.isDisabled },
                        on: { click: _vm.sendSubOrder },
                      },
                      [_vm._v("Send Order")]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.sub_order.order_items.data, function (order_item) {
          return _c("order-item", {
            key: order_item.id,
            attrs: {
              order_item: order_item,
              sub_order: _vm.sub_order,
              order_expired: _vm.orderExpired,
            },
            on: {
              "update-order-item": _vm.updateOrderItem,
              "update-sub-order": _vm.updateSubOrder,
              "review-modal": _vm.reviewModal,
              "show-review-modal": _vm.showReviewModal,
            },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }