var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "right-column" }, [
    _c("div", { staticClass: "toggle-details" }, [
      !_vm.isVisible
        ? _c("p", { on: { click: _vm.detailsToggle } }, [_vm._v("View Order")])
        : _c("p", { on: { click: _vm.detailsToggle } }, [_vm._v("Hide Order")]),
      _vm._v(" "),
      _c("span", { attrs: { id: "toggle-chevron" } }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "toggle-div" }, [
      _c(
        "div",
        { staticClass: "checkout-details" },
        [
          _c("h3", { staticClass: "plan-title" }, [
            _vm._v(_vm._s(_vm.planName)),
          ]),
          _vm._v(" "),
          _vm.isSubscription
            ? _c("div", { staticClass: "membership-details" }, [
                _c("div", { staticClass: "details-spacer-mobile" }),
                _vm._v(" "),
                _c("h5", { staticClass: "details-h5 membership" }, [
                  _vm._v("Membership Summary"),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Subscription plan membership is free for the first month."
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "After that, your card will be charged $59 every month."
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "details-spacer" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h5", { staticClass: "details-h5" }, [_vm._v("Order Summary")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "items-container" },
            _vm._l(_vm.products, function (product) {
              return _c("div", { key: product.id, staticClass: "order-item" }, [
                _c("a", { attrs: { href: product.links.public } }, [
                  product.image.data
                    ? _c("img", {
                        attrs: {
                          src: product.image.data.variants.square_thumb,
                          alt: "image",
                        },
                      })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "order-item-details" }, [
                  _c("p", [_vm._v(_vm._s(product.title))]),
                  _vm._v(" "),
                  _c("ul"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _c("p", [_vm._v(_vm._s(product.rental_format) + "/day")]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.isSubscription
            ? _c("div", { staticClass: "prices prices-checkout" }, [
                _c("div", { staticClass: "prices-line" }, [
                  _c("p", [_vm._v("Subtotal")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.subscriptionPrice))]),
                ]),
                _vm._v(" "),
                this.couponCode
                  ? _c("div", { staticClass: "prices-line coupon-line" }, [
                      _c("p", { staticClass: "coupon-wrapper" }, [
                        _vm._v(
                          'Code Applied "' + _vm._s(this.couponCode) + '"'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("-" + _vm._s(this.couponDiscount))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.couponCode
                  ? _c("div", { staticClass: "remove-code" }, [
                      _c("a", { attrs: { href: "/cart/remove_coupon" } }, [
                        _vm._v("remove"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _vm.isFirstMonth
                  ? _c("div", { staticClass: "prices-line" }, [
                      _c("p", [_vm._v("One Month Free Promotion")]),
                      _vm._v(" "),
                      _c("p", [_vm._v("-" + _vm._s(_vm.subscriptionPrice))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "prices-line" }, [
                  _c("p", [_vm._v("TOTAL")]),
                  _vm._v(" "),
                  _vm.isFirstMonth
                    ? _c("p", [_vm._v("$0.00")])
                    : _c("p", [_vm._v(_vm._s(_vm.subscriptionPrice))]),
                ]),
              ])
            : _c("div", { staticClass: "prices prices-checkout" }, [
                _c("div", { staticClass: "prices-line" }, [
                  _c("p", [_vm._v("Subtotal")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(this.order.rental_price_formatted))]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "prices-line" }, [
                  _c("p", [_vm._v("Store credit")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(this.order.store_credit_formatted))]),
                ]),
                _vm._v(" "),
                this.couponCode
                  ? _c("div", { staticClass: "prices-line coupon-line" }, [
                      _c("p", [
                        _vm._v(
                          'Code Applied "' + _vm._s(this.couponCode) + '"'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("-" + _vm._s(this.couponDiscount))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.couponCode
                  ? _c("div", { staticClass: "remove-code" }, [
                      _c("a", { attrs: { href: "/cart/remove_coupon" } }, [
                        _vm._v("remove"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "prices-line" }, [
                  _c("p", [_vm._v("TOTAL")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(this.finalPrice))]),
                ]),
              ]),
          _vm._v(" "),
          _c("hr", { staticClass: "after-prices" }),
          _vm._v(" "),
          _c("div", { staticClass: "dates" }, [
            _c("p", [_vm._v("Estimated Delivery " + _vm._s(this.rent_at))]),
            _vm._v(" "),
            _c("p", [_vm._v("Return Date " + _vm._s(this.return_at))]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          !this.couponCode & !this.isSubscription
            ? _c("p", { staticClass: "promo" }, [
                _vm._v("\n        Gift/Promo Code\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          !this.couponCode & !this.isSubscription
            ? _c("coupon", {
                attrs: { path: "/cart/coupon" },
                on: { updateCoupon: _vm.updateCoupon },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(3),
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "hr-mobile" }),
    _vm._v(" "),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [_vm._v("Automatic renewal for your convenience.")]),
      _vm._v(" "),
      _c("li", [_vm._v("Cancel anytime.")]),
      _vm._v(" "),
      _c("li", [_vm._v("No commitment.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prices-line" }, [
      _c("p", [_vm._v("Shipping Fee")]),
      _vm._v(" "),
      _c("p", [_vm._v("FREE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "prices-line" }, [
      _c("p", [_vm._v("Shipping Fee")]),
      _vm._v(" "),
      _c("p", [_vm._v("FREE")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "need-help" }, [
      _vm._v("\n      Need help or have questions? Read our "),
      _c("a", { attrs: { href: "/faq" } }, [_vm._v("FAQ.")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav", attrs: { id: "nav-mobile" } }, [
      _c("div", { staticClass: "before-chevron" }),
      _vm._v(" "),
      _c("a", { staticClass: "back-to-cart", attrs: { href: "/" } }, [
        _vm._v("Back to Shopping Cart"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }