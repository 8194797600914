var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "product-row" }, [
    _c("td", [_c("img", { attrs: { src: _vm.item.attributes.thumb } })]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.item.attributes.title))]),
    _vm._v(" "),
    _c("td", [
      _c("strong", [_vm._v(_vm._s(_vm.item.attributes.vendor_name))]),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.item.attributes.company_name))]),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("strong", [_vm._v(_vm._s(_vm.item.attributes.rental_format))]),
      _vm._v("\n    daily\n    "),
      _c("div", [_vm._v(_vm._s(_vm.item.attributes.rental_price))]),
      _vm._v(" "),
      _c("span", { staticClass: "badge badge-outline badge-primary" }, [
        _vm._v(_vm._s(_vm.item.attributes.purchase_option)),
      ]),
    ]),
    _vm._v(" "),
    _c("td", [
      _c(
        "a",
        {
          staticClass: "btn btn-primary btn-outline",
          attrs: { href: "/admin/products/" + _vm.item.attributes.slug },
        },
        [_vm._v("Edit")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }