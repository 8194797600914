var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: "slide-fade" }, on: { "after-leave": _vm.afterLeave } },
    [
      _vm.show
        ? _c("div", { staticClass: "col-3" }, [
            _c(
              "div",
              { staticClass: "p-1 border border-light my-2 image-wrapper" },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-wrapper border",
                    on: { click: _vm.removeItem },
                  },
                  [_c("i", { staticClass: "icon fas fa-times" })]
                ),
                _vm._v(" "),
                _c("img", { attrs: { src: _vm.item.variants.square_thumb } }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group string" }, [
                  _c("label", { staticClass: "form-control-label string" }, [
                    _vm._v("Alt text"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.item.alt_text,
                        expression: "item.alt_text",
                      },
                    ],
                    staticClass: "form-control string",
                    attrs: { type: "text" },
                    domProps: { value: _vm.item.alt_text },
                    on: {
                      keyup: _vm.update,
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.item, "alt_text", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }