<template lang="html">
  <div id="cart-container" style="right: -100vw;">
    <h3 class="cart-plan">RENT BY THE DAY</h3>
    <div :class="'cart-main-info ' + order.shopping_mode">
      <p id="length-var">1</p>
      <div id="arrow-hide"><svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"></path></svg></div>
      <h2 class="cart-title">
        YOUR SHOPPING CART (
         {{ this.order_items && this.order_items.length }}
        )
      </h2>
      <p v-if="emptyProductsCount" class="pieces-left">
        You have
        <span>up to</span>
        <span id="pieces-left-number">{{ emptyProductsCount }}</span>
        <span>pieces</span>
        left to pick!
      </p>
      <p v-else class="pieces-left">Your cart is full!</p>
    </div>

    <div class="cart-items">
      <template v-for="item in order_items">
        <div class="cart-product">
          <div class="cart-image">
            <a :href="'/rent-jewelry/'+item.slug">
              <img :src="item.image_urls.square_thumb" />
            </a>
          </div>
          <div class="cart-product-details">
            <p class="brand">{{ item.brand }}</p>
            <p class="title"><a class="title" :href="'/rent-jewelry/'+item.slug">{{ item.name }}</a></p>
            <a class="delete-link" @click.prevent="deleteItem(item.variant_id)" href="javascript:;">
              <svg class="delete-svg" height="50" stroke-linecap="round" stroke-width="3" stroke="black" viewBox="0 0 50 50" width="50">
                <line x1="0" x2="50" y1="0" y2="50"></line>
                <line x1="50" x2="0" y1="0" y2="50"></line>
              </svg>
            </a>
            <div class="product-price-other">
              <p class="other"></p>
              <p class="price">
              {{ item.rental_price_formatted }}
              /day
              </p>
            </div>
          </div>
        </div>
        <small v-if="not_available_order_items_ids.includes(item.id)" class="not-available text-danger">
          Product not available
        </small>
      </template>

      <div v-for="index in emptyProductsCount" :key="index" class="empty-cart-product">
        <div :class="'empty-image ' + order.shopping_mode">
          <p>PIECE</p>
          <h1>{{order_items.length + index}}</h1>
        </div>
        <div class="empty-cart-link">
          <a v-if="order.shopping_mode == 'subscription'" class="browse-redirect" href="/rent-jewelry?filter=sub-box">Continue to browse</a>
          <a v-else class="browse-redirect" href="/rent-jewelry">Continue to browse</a>
        </div>
      </div>
    </div>

    <div class="cart-order-footer" v-if="order_items && order_items.length != 0">
      <p>ONE date for your chosen pieces:</p>
      <p>Delivery Date {{ this.order_data.rent_at_formatted }}</p>
      <p>Return Date {{ this.order_data.return_at_formatted }}</p>
      <div class="tooltip-container">
        <div class="tooltip-hover">
          Pick different dates?
          <span class="tooltip-text">Click on any of the jewelry pieces reserved in your shopping cart. Then change the rental date on the calendar. Date will automatically change for any additional jewelry you already have reserved.</span>
        </div>
        <div class="tooltip-hover">
          Pick different dates for EACH piece of jewelry?
          <span class="tooltip-text">Please reserve and checkout EACH piece of jewelry in a separate transaction.</span>
        </div>
      </div>
      <hr/>
      <div class="prices">
        <div class="prices-line">
          <p>Subtotal</p>
          <p class="amounts">
          {{ order.rental_price_formatted }}
          </p>
        </div>
        <div class="prices-line">
          <p>Store credit</p>
          <p class="amounts">{{ order.store_credit_formatted }}</p>
        </div>
        <div class="prices-line">
          <p>Delivery Fee</p>
          <p class="amounts">FREE</p>
        </div>
        <template v-if="coupon">
          <div class="code-line">
            <div class="code-details">
              Code Applied: "
              {{ coupon.code }}
              "
            </div>
            <div class="code-details">
              {{ order.rental_discount_formatted }}
            </div>
          </div>
          <div class="remove-coupon">
            <a href="/cart/remove_coupon">remove</a>
          </div>
        </template>
        <div class="prices-line">
          <p>TOTAL</p>
          <p class="amounts">
          {{ order.rental_end_price_formatted }}
          </p>
        </div>
      </div>
    </div>

    <div id="coupon-app">
      <CouponCart :path="path"/>
    </div>
    <div class="continue-container rbtd">
      <a class="continue-btn-rbtd" href="/cart/checkout">CHECKOUT</a>
    </div>
  </div>
</template>

<script>
import * as Toastr from "toastr";
import EventBus from '../../event_bus';
import GtmEvents from '../../gtm_events';
import CouponCart from '../checkout/coupon_cart';

export default {
  components: {
    CouponCart
  },
  props: {
    order: { type: Object },
    store_credit: { type: String },
    path: { type: String }
  },
  data() {
    return {
      order_items: this.order.order_items,
      not_available_order_items_ids: this.order.not_available_order_items_ids,
      coupon: this.order.coupon,
      order_data: this.order,
    };
  },
  mounted() {
    EventBus.$on('cartReload', (data) => {
      this.reload(data);
    });
    EventBus.$on('couponDivReload', (coupon) => {
      this.coupon = coupon;
      this.order.rental_discount_formatted = coupon.discountedPrice;
      this.order.rental_end_price_formatted = coupon.finalPrice;
    });
  },
  methods: {
    reload: function(data) {
      if(data.startDateFormatted) {
        this.order.rent_at_formatted = data.startDateFormatted;
        this.order.return_at_formatted = data.endDateFormatted;
      }

      if(data.shopping_mode) {
        this.order.shopping_mode = data.shopping_mode;
      }

      this.order.rental_price_formatted = data.order.rental_price_formatted;
      this.order.rental_discount_formatted = data.order.rental_discount_formatted;
      this.order.rental_end_price_formatted = data.order.rental_end_price_formatted;

      if(data.removedFromCartId) {
        this.deleteItemFrontend(data.removedFromCartId);
      } else if(data.addedToCartId) {
        this.addItem(data);
      }
      
      this.cartItemsCountSet(data.order.items_count);
    },
    deleteItem: function(id) {
      this.deleteItemFrontend(id);
      this.deleteItemBackend(id);
    },
    deleteItemFrontend: function(id) {
      this.order_items = this.order_items.filter(item => item.variant_id !== id);
      if(this.order_items.length == 0) {
        this.order.shopping_mode = 'rbtd';
      }
    },
    deleteItemBackend: function(id) {
      var that = this;
      
      $.ajax({
        method: "DELETE",
        data: {
          cart: {
            variant_id: id,
          },
        },
        url: "/cart",
        success: function (data) {
          that.$parent.order = data.order;
          that.$parent.calendar = data.calendar;
          $(".nav-link-cart span").text(data.order.items_count);
          Toastr.success("Product removed from cart!", "Success");
          data.removedFromCartId = +that.productId;
          that.reload(data);

          GtmEvents.trackItemEvent('remove_from_cart', { order_data: data })
          EventBus.$emit('buttonReload', data.order);
        },
        error: function (data) {
          Toastr.error(data.responseJSON.errors.join(), "Reserve error");
        },
      });
    },
    addItem: function(data) {
      this.order_items.push(data.order_item);
    },
    cartItemsCountSet: function(count) {
      if(count) {
        $(".cart-items-number").text(count);
        $(".cart-items-number").removeClass("hidden");
        $(".cart-items-number").addClass("cart-items-number-" + this.order.shopping_mode);
      } else {
        $(".cart-items-number").addClass("hidden");
      }
    }
  },
  computed: {
    emptyProductsCount: function () {
      if(this.order_items) {
        return 3 - this.order_items.length;
      } else {
        return 0;
      }
    }
  }
};
</script>

<style lang="scss">
</style>
