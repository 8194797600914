import { render, staticRenderFns } from "./modal.vue?vue&type=template&id=1c625756&scoped=true&lang=html"
import script from "./modal.vue?vue&type=script&lang=js"
export * from "./modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c625756",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/usr/src/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c625756')) {
      api.createRecord('1c625756', component.options)
    } else {
      api.reload('1c625756', component.options)
    }
    module.hot.accept("./modal.vue?vue&type=template&id=1c625756&scoped=true&lang=html", function () {
      api.rerender('1c625756', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/client/javascripts/app/components/refer_friend/modal.vue"
export default component.exports